import { gql, useLazyQuery, useMutation } from '@apollo/client';
import MapMarkerRadius from '@components/icons/mapMarkerRadius';
import EditDynamicProperty from '@components/modals/EditDynamicProperty';
import SelectSchema from '@components/modals/SelectSchema';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import FilterGeotagsModal from '@components/side-card/monitor-status-table/FilterGeotagsModal';
import FilterGroupModal from '@components/side-card/monitor-status-table/FilterGroupModal';
import { AvTimer, Label } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import ExtensionIcon from '@mui/icons-material/Extension';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import TuneIcon from '@mui/icons-material/Tune';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { getPropertyByKey } from '@utils/getPropertyByKey';
import { useEffect, useState } from 'react';

import tabStyle from '@components/side-card/tab.module.css';
import ParameterDateRange from './modals/ParameterDateRange';
import ParameterPeriod from './modals/ParameterPeriod';
import ParameterPropertyModal from './modals/ParameterPropertyModal';
import RpcSubscribeWrapper from './RpcSubscribeWrapper';

const UPDATE_PROPERTIES_BY_IDS = gql`
  mutation updateObjectPropertiesById($input: UpdateObjectPropertiesByIdInput!) {
    updateObjectPropertiesById(input: $input) {
      clientMutationId
    }
  }
`;

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      clientMutationId
      controlExecution {
        id
      }
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_SCHEMA = gql`
  query getSchema($id: UUID!) {
    schema(id: $id) {
      id
      name
    }
  }
`;

const PropertyHistoryTableGeneral = ({ item }) => {
  const [createExecution] = useMutation(CREATE_EXECUTION);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  const valueValue = () => {
    return item.objectProperties.find((item) => item.key === 'valueValue');
  };

  const settingsProperty = () => {
    return item.objectProperties.find((item) => item.key === 'settingsProperty');
  };

  const settingsLinkedOnly = () => {
    return item.objectProperties.find((item) => item.key === 'settingsLinkedOnly');
  };

  const settingsPeriod = () => {
    return item.objectProperties.find((item) => item.key === 'settingsPeriod');
  };

  const settingsDateRange = () => {
    return item.objectProperties.find((item) => item.key === 'settingsDateRange');
  };

  const settingsSchema = () => {
    return item.objectProperties.find((item) => item.key === 'settingsSchema');
  };

  const geotagName = () => {
    return getPropertyByKey(item.objectProperties, 'settingsGeotag').value?.title || 'All geotags';
  };

  const groupName = () => {
    return getPropertyByKey(item.objectProperties, 'settingsGroup').value?.title || 'All groups';
  };

  const [loadSchema, { data }] = useLazyQuery(GET_SCHEMA);

  const schemaName = () => {
    if (!settingsSchema().value) {
      return 'n/a';
    } else {
      return data?.schema?.name || 'n/a';
    }
  };

  // const [parameterObject, setParameterObject] = useState(settingsProperty().value);
  const [parameterDateRange] = useState(settingsDateRange().value);
  const [parameterPeriods] = useState(settingsPeriod().value);
  const [isMayGetData, setIsMayGetData] = useState(false);

  useEffect(() => {
    if (!settingsSchema().value || !settingsDateRange().value.value) {
      setIsMayGetData(false);
    } else {
      setIsMayGetData(true);
    }
  }, [item]);

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'UpdateReport',
            objectId: item.id,
            params: {
              // object: parameterObject.value,
              period: parameterPeriods.value,
              range: parameterDateRange.value,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (settingsSchema().value) {
      loadSchema({
        variables: {
          id: settingsSchema().value,
        },
      }).then(() => {});
    }
  }, [item]);

  return (
    <>
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Parameters</Typography>
        </ListSubheader>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }}>
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Schema: {schemaName()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                SelectSchema({
                  handleSave: (id, value) => {
                    return updateProperties({
                      variables: {
                        input: {
                          propertiesArray: [
                            {
                              propertyId: id,
                              value: value,
                            },
                            {
                              propertyId: settingsProperty().id,
                              value: { title: 'n/a', value: null },
                            },
                            {
                              propertyId: valueValue().id,
                              value: [],
                            },
                          ],
                        },
                      },
                    });
                  },
                  id: settingsSchema().id,
                  value: settingsSchema().value,
                })
                  .then()
                  .catch();
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
        >
          <ListItemIcon>
            <ExtensionIcon></ExtensionIcon>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Property: {settingsProperty().value.title}</Typography>} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              size="small"
              onClick={() => {
                ParameterPropertyModal({
                  id: item.id,
                  schemaId: settingsSchema().value ? data?.schema?.id : null,
                  propertyId: settingsProperty()?.value?.value || null,
                  save: (property) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsProperty().id,
                          patch: {
                            value: property || { title: 'n/a', value: null },
                          },
                        },
                      },
                    }).then();
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
        >
          <ListItemIcon>
            <EventIcon></EventIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Date: {settingsDateRange().value.title}</Typography>}
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              size="small"
              onClick={() => {
                ParameterDateRange({
                  selected: settingsDateRange(),
                  save: (date) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsDateRange().id,
                          patch: {
                            value: date,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
        >
          <ListItemIcon>
            <AvTimer></AvTimer>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Aggregation: {settingsPeriod().value.title}</Typography>}
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              onClick={() => {
                ParameterPeriod({
                  selected: settingsPeriod(),
                  save: (period) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsPeriod().id,
                          patch: {
                            value: period,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Filters</Typography>
        </ListSubheader>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }}>
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">Linking: {settingsLinkedOnly().value ? 'Linked only' : 'All'}</Typography>
            }
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              size="small"
              edge="end"
              onClick={() => {
                const target = settingsLinkedOnly();
                EditDynamicProperty({
                  handleSave: handleUpdateProperty,
                  property: target,
                  id: target.id,
                  value: target.value,
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <Label />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Group: {groupName()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getPropertyByKey(item.objectProperties, 'settingsGroup');
                FilterGroupModal({
                  value: target.value,
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: object ? object : { value: null, title: 'n/a' },
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <MapMarkerRadius />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Geotag: {geotagName()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getPropertyByKey(item.objectProperties, 'settingsGeotag');
                FilterGeotagsModal({
                  value: target.value,
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: object ? object : { value: null, title: 'n/a' },
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={'UpdateReport'}
            objectId={item.id}
            disabled={!isMayGetData}
            object={item}
            title={'Update table'}
            handler={rpcHandler}
          >
            <LoadingButton fullWidth={true} variant={'outlined'}></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default PropertyHistoryTableGeneral;
