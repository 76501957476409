import { gql, useMutation } from '@apollo/client';
import { TimerOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import TuneIcon from '@mui/icons-material/Tune';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { format, parseISO } from 'date-fns';
import { loader } from 'graphql.macro';
import { Suspense } from 'react';
import { getPropertyByKey } from '../../../utils/getPropertyByKey';
import ClockOutline from '../../icons/clockOutline';
import FunctionIcon from '../../icons/functionIcon';
import EditDynamicProperty from '../../modals/EditDynamicProperty';
import SelectSchema from '../../modals/SelectSchema';
import EditFunction from '../geo-timer/EditFunction';
import HumanReadableProperty from '../geo-timer/HumanReadableProperty';
import MinimumDurationModal from '../geotags-table/modals/MinimumDurationModal';
import MotionTimeoutModal from '../geotags-table/modals/MotionTimeoutModal';
import ParameterDateRange from '../static-table/modals/ParameterDateRange';
const UPDATE_OBJECT_PROPERTIES = loader('../../../graphql/UpdateObjectWithProperties.graphql');

const GET_SCHEMA_BY_ID = gql`
  query getSchema($id: UUID!) {
    schema(id: $id) {
      id
      name
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const ParametersSection = ({ classes, item }) => {
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [updateProperties] = useMutation(UPDATE_OBJECT_PROPERTIES);

  const handleUpdateProperties = (values) => {
    const valuesReady = [...values];

    return updateProperties({
      variables: {
        input: {
          detailedObject: [{ objectId: item.id, keyedProperties: valuesReady }],
        },
      },
    });
  };

  const dateRange = (date) => {
    const formatDate = (date) => format(parseISO(date), 'dd-MM-yyyy');
    const value = date ?? getPropertyByKey(item.objectProperties, 'parametersDateRange')?.value;

    if (!value?.length) {
      return 'n/a';
    }

    return `${formatDate(value[0])} — ${formatDate(value[1])}`;
  };

  const functionInfo = () => {
    const funcValue = getPropertyByKey(item.objectProperties, 'parametersFunction')?.value;
    const funcName = getPropertyByKey(item.objectProperties, 'parametersFunction')?.spec?.valueSet?.list?.find(
      (item) => item.key === funcValue
    )?.title;

    const units = getPropertyByKey(item.objectProperties, 'parametersFunctionUnits')?.value;
    let resultName = '';

    if (!funcName) {
      return 'n/a';
    } else {
      resultName += funcName;

      if (units) {
        resultName += ` (${units})`;
      }

      return resultName;
    }
  };

  const linkedOnly = () => {
    return getPropertyByKey(item.objectProperties, 'parametersLinkedOnly')?.value ? 'Linked only' : 'All';
  };

  const parametersSchemaSelect = () => {
    const geoTagValue = schemaType();

    return {
      query: GET_SCHEMA_BY_ID,
      config: {
        variables: {
          id: geoTagValue,
        },
      },
    };
  };

  const schemaType = () => {
    return getPropertyByKey(item.objectProperties, 'parametersSchema')?.value;
  };

  const parametersMinimumDuration = () => {
    return getPropertyByKey(item.objectProperties, 'parametersMinimumDuration');
  };

  const parametersNoMotionTimeout = () => {
    return getPropertyByKey(item.objectProperties, 'parametersNoMotionTimeout');
  };

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  return (
    <>
      <ListSubheader color="primary" className={classes.listSubheader}>
        <Typography variant="subtitle2">Parameters</Typography>
      </ListSubheader>

      <ListItem classes={{ container: classes.itemToHover }}>
        <ListItemIcon>
          <SubtitlesIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1">
              <span>Type: </span>
              {schemaType() && (
                <>
                  <Suspense fallback={<span>Loading...</span>}>
                    <HumanReadableProperty
                      payload={parametersSchemaSelect()}
                      getValue={(data) => data?.schema?.name || 'n/a'}
                    ></HumanReadableProperty>
                  </Suspense>
                </>
              )}
              {!schemaType() && <span>All types</span>}
            </Typography>
          }
        />
        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton
            edge="end"
            onClick={() => {
              const target = getPropertyByKey(item.objectProperties, 'parametersSchema');
              SelectSchema({
                handleSave: handleUpdateProperty,
                id: target.id,
                value: target.value,
              })
                .then()
                .catch(() => {});
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem classes={{ container: classes.itemToHover }}>
        <ListItemIcon>
          <TuneIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body1">Linking: {linkedOnly()}</Typography>} />
        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton
            edge="end"
            onClick={() => {
              const target = getPropertyByKey(item.objectProperties, 'parametersLinkedOnly');

              EditDynamicProperty({
                handleSave: handleUpdateProperty,
                property: target,
                id: target.id,
                value: target.value,
              })
                .then()
                .catch(() => {});
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem
        classes={{
          container: classes.itemToHover,
        }}
        style={{ paddingRight: '96px' }}
      >
        <ListItemIcon>
          <EventIcon></EventIcon>
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body1">Date: {dateRange()}</Typography>} />
        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton
            edge="end"
            onClick={() => {
              const target = getPropertyByKey(item.objectProperties, 'parametersDateRange');

              ParameterDateRange({
                formatStart: 'yyyy-MM-dd',
                formatEnd: 'yyyy-MM-dd',
                selected: { value: target },
                save: (date) => {
                  updateProperty({
                    variables: {
                      input: {
                        id: target.id,
                        patch: {
                          value: date.value,
                        },
                      },
                    },
                  }).then(() => {});
                },
              }).then(() => {});
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem classes={{ container: classes.itemToHover }}>
        <ListItemIcon>
          <FunctionIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body1">Function: {functionInfo()}</Typography>} />
        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton
            edge="end"
            aria-label="more"
            onClick={() => {
              const parametersFunction = getPropertyByKey(item.objectProperties, 'parametersFunction');
              const parametersFunctionUnits = getPropertyByKey(item.objectProperties, 'parametersFunctionUnits');

              EditFunction({
                handleSave: handleUpdateProperties,
                properties: {
                  parametersFunction,
                  parametersFunctionUnits,
                },
              })
                .then(() => {})
                .catch(() => {});
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        classes={{
          container: classes.itemToHover,
        }}
        style={{ height: '48px', paddingRight: '96px' }}
      >
        <ListItemIcon>
          <ClockOutline></ClockOutline>
        </ListItemIcon>
        <ListItemText
          primary={<Typography variant="body1">Minimum duration: {parametersMinimumDuration().value} sec</Typography>}
        />
        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton
            onClick={() => {
              MinimumDurationModal({
                value: parametersMinimumDuration()?.value,
                handleSave: (value) =>
                  updateProperty({
                    variables: {
                      input: {
                        id: parametersMinimumDuration().id,
                        patch: {
                          value,
                        },
                      },
                    },
                  }),
              });
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        classes={{
          container: classes.itemToHover,
        }}
        style={{ height: '48px', paddingRight: '96px' }}
      >
        <ListItemIcon>
          <TimerOutlined></TimerOutlined>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1">No motion timeout: {parametersNoMotionTimeout()?.value || 0} sec</Typography>
          }
        />
        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton
            onClick={() => {
              MotionTimeoutModal({
                value: parametersNoMotionTimeout()?.value,
                handleSave: (value) =>
                  updateProperty({
                    variables: {
                      input: {
                        id: parametersNoMotionTimeout().id,
                        patch: {
                          value,
                        },
                      },
                    },
                  }),
              });
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default ParametersSection;
