import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TodayIcon from '@mui/icons-material/Today';
import { Divider, IconButton, Typography } from '@mui/material';
import { formatISO, sub } from 'date-fns';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import useRoute from '../../../hooks/useRoute';
import DateModal from '../../modals/DateModal';
import useMoreMenu from '../../useMoreMenu';
import { Header } from './HistoryControl.styled';

type HistoryControlsToolbarProps = {
  name: string;
  setRecordedFilter: Dispatch<SetStateAction<string[]>>;
  setAfter: Dispatch<SetStateAction<any>>;
  downloadHistory: any;
  type: string;
  downloadIds: string[];
};

const HistoryControlsToolbar: FC<HistoryControlsToolbarProps> = (props) => {
  const { name, setRecordedFilter, setAfter, downloadHistory, downloadIds } = props;

  const { MoreMenu: HistoryControlsMenu, openMoreMenu: openHistorControlsMenu } = useMoreMenu();

  const { handleGoBack } = useRoute();

  const [date, setDate] = useState('day');

  const menu = [
    {
      icon: <TodayIcon />,
      title: 'Day',
      id: 'day',
      disabled: false,
      checked: date === 'day',
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { days: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('day');
      },
    },
    {
      icon: <TodayIcon />,
      title: 'Week',
      id: 'week',
      checked: date === 'week',
      disabled: false,
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { weeks: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('week');
      },
    },
    {
      icon: <TodayIcon />,
      title: 'Month',
      id: 'month',
      checked: date === 'month',
      disabled: false,
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { months: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('month');
      },
    },
    {
      id: 'divider',
    },
    {
      icon: <GetAppIcon />,
      title: 'Download',
      id: 'download history',
      disabled: false,
      handleAction: () => {
        DateModal({
          downloadHistory,
          downloadIds,
          enableWide: true,
          type: props.type,
        })
          .then()
          .catch(() => {});
      },
    },
  ];
  return (
    <>
      <HistoryControlsMenu checkable items={menu} />
      <Header variant="dense">
        <IconButton style={{ marginRight: '16px' }} onClick={handleGoBack} size="medium">
          <ArrowBackIcon />
        </IconButton>
        <Typography
          noWrap={true}
          style={{
            display: 'block',
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="h6"
          title={name}
        >
          {name}
        </Typography>
        <IconButton aria-label="settings" onClick={openHistorControlsMenu} size="large" data-test-settings="settings">
          <MoreVertIcon />
        </IconButton>
      </Header>

      <Divider />
    </>
  );
};

export default HistoryControlsToolbar;
