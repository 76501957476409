import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import { IFormValues, validationSchema } from '../constants';

const TYPE_COLUMN_LIST = [
  {
    title: 'Value',
    value: 'value',
  },
  {
    title: 'Last update',
    value: 'lastUpdate',
  },
];

export const Value = ({ form, label, field }: FieldProps<IFormValues> & { label: string }) => {
  return (
    <Grid item>
      <CustomSelect {...fieldRequiredProps(form, validationSchema, field.name)} label={label} list={TYPE_COLUMN_LIST} />
    </Grid>
  );
};
