import { ObjectProperty } from '@src/__generated__/graphql';
import { WIDGETS_ENUM } from '@utils/widgetTypes';
import { lazy, Suspense } from 'react';

export type WidgetProps = {
  id?: string;
  name?: string;
  title?: string;
  objectProperties?: ObjectProperty[];
  selected?: boolean;
  object?: {
    schemaTags: string[];
  };
  [key: string]: unknown;
};

const components = {
  [WIDGETS_ENUM.TITLE]: lazy(() => import('../../../components/widgets/title/W_Title')),
  [WIDGETS_ENUM.ADVANCED_BUTTON]: lazy(() => import('../../../components/widgets/W_AdvancedButton')),
  [WIDGETS_ENUM.COMMAND_BUTTON]: lazy(() => import('@components/widgets/W_CommandButton')),
  [WIDGETS_ENUM.DATABOX]: lazy(() => import('../../../components/widgets/W_DataBox')),
  [WIDGETS_ENUM.TIMER]: lazy(() => import('../../../components/widgets/W_Timer')),
  [WIDGETS_ENUM.DATETIME]: lazy(() => import('../../../components/widgets/W_DateTime')),
  [WIDGETS_ENUM.HISTORY_TABLE]: lazy(() => import('../../../components/widgets/W_HistoryTable')),
  [WIDGETS_ENUM.STATIC_TABLE]: lazy(() => import('../../../components/widgets/static-table/index')),
  [WIDGETS_ENUM.LINE]: lazy(() => import('../../../components/widgets/W_Line')),
  [WIDGETS_ENUM.MONITOR_TABLE]: lazy(() => import('../../../components/widgets/W_MonitorTable')),
  [WIDGETS_ENUM.MONITOR_STATUS]: lazy(() => import('../../../components/widgets/W_MonitorStatusTable')),
  [WIDGETS_ENUM.DATACHART]: lazy(() => import('../../../components/widgets/chart/W_DataChart')),
  [WIDGETS_ENUM.DIAGRAM]: lazy(() => import('../../../components/widgets/W_DiagramChart')),
  [WIDGETS_ENUM.GEO_TIMER]: lazy(() => import('../../../components/widgets/geo-timer/GeoTimer')),
  [WIDGETS_ENUM.TRACKING_TABLE]: lazy(() => import('../../../components/widgets/tracking-table/W_TrackingTable')),
  [WIDGETS_ENUM.PROPERTY_HISTORY_TABLE]: lazy(() => import('../../../components/widgets/property-history-table/index')),
  [WIDGETS_ENUM.GEOTAGS_TABLE]: lazy(() => import('../../../components/widgets/geotags-table/index')),
};

const WidgetWrap = (props: WidgetProps) => {
  const WidgetComponent = components[props.object.schemaTags[3]];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <WidgetComponent {...props} />
    </Suspense>
  );
};

export default WidgetWrap;
