import { LazyQueryResultTuple, OperationVariables, useLazyQuery } from '@apollo/client';
import { CircularProgress, List, ListItem, Typography } from '@mui/material';
import { getControlsHistory } from '@src/media-server';
import { ControlExecutionsConnection, PageInfo } from '@src/__generated__/graphql';
import downloadBlob from '@utils/downloadBlob';
import { WIDGETS_ENUM } from '@utils/widgetTypes';
import { format, formatISO, sub } from 'date-fns';
import { loader } from 'graphql.macro';
import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Content, Root } from './HistoryControl.styled';
import HistoryControlsItem from './HistoryControlsItem';
import HistoryControlsToolbar from './HistoryControlsToolbar';
import { HistoryControlsPropertiesProps } from './HistoryControlsTypes';

const GET_CONTROLS_HISTORY = loader('../../../graphql/GetControls.graphql');

const HistoryControlsProperties: FC<HistoryControlsPropertiesProps> = (props) => {
  const { id, object } = props;
  const [recordedFilter, setRecordedFilter] = useState([
    formatISO(sub(Date.now(), { days: 1 })),
    formatISO(Date.now()),
  ]);
  const buttonCondition =
    object.schemaTags.includes(WIDGETS_ENUM.ADVANCED_BUTTON) || object.schemaTags.includes(WIDGETS_ENUM.COMMAND_BUTTON);

  function defineId(): string {
    if (buttonCondition) {
      const objectId = object.objectProperties.find((obj) => obj.key === 'valueValue')?.value?.objectId as string;
      const objectIdFromValueAction = object.objectProperties.find((obj) => obj.key === 'valueAction')?.value
        ?.objectId as string;
      return objectIdFromValueAction || objectId || id;
    }
    return id;
  }

  const [after, setAfter] = useState<string>(null);
  const [loadControlsHistory, { data }]: LazyQueryResultTuple<
    { controlExecutionsConnection: ControlExecutionsConnection },
    OperationVariables
  > = useLazyQuery(GET_CONTROLS_HISTORY, {
    variables: {
      first: 30,
      after,
      filter: {
        type: {
          equalTo: 'RPC',
        },
        objectId: {
          equalTo: defineId(),
        },
        createdAt: {
          greaterThanOrEqualTo: recordedFilter[0],
          lessThanOrEqualTo: recordedFilter[1],
        },
      },
      orderBy: 'CREATED_AT_DESC',
    },
    fetchPolicy: 'cache-and-network',
  });

  const endMessage = buttonCondition && !defineId() ? 'Source is not assigned' : 'No more data';

  const downloadHistory = async (range: (number | Date)[], ids: string[]) => {
    const obj = {
      objects: ids,
      from: format(range[0], 'yyyy-MM-dd 00:00'),
      to: format(range[1], 'yyyy-MM-dd 23:59'),
    };

    const res = await getControlsHistory(obj);
    downloadBlob(res.data, `PixelBoard_ControlsHistory_${obj.from}-${obj.to}.csv`, res.data.type);
  };

  useEffect(() => {
    loadControlsHistory()
      .then(() => {})
      .catch(() => {});
  }, [id, recordedFilter]);

  const fetchData = () => {
    if (data.controlExecutionsConnection.pageInfo.hasNextPage) {
      setAfter(data.controlExecutionsConnection.pageInfo.endCursor as string);
    }
    return null;
  };

  const pageInfo = (datas: { controlExecutionsConnection: ControlExecutionsConnection }): PageInfo => {
    if (datas) {
      return datas.controlExecutionsConnection.pageInfo;
    }
    return null;
  };

  return (
    <Root>
      <HistoryControlsToolbar
        name={object.name}
        setRecordedFilter={setRecordedFilter}
        setAfter={setAfter}
        downloadHistory={downloadHistory}
        type="controls"
        downloadIds={[defineId()]}
      />
      <Content id="scrollContainer">
        <List>
          <InfiniteScroll
            scrollableTarget="scrollContainer"
            dataLength={data?.controlExecutionsConnection.edges?.length || 0}
            next={fetchData}
            hasMore={pageInfo(data) ? pageInfo(data).hasNextPage : true}
            loader={
              <ListItem
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={24} />
              </ListItem>
            }
            endMessage={
              <Typography variant={'body1'} style={{ textAlign: 'center' }}>
                <span>{endMessage}</span>
              </Typography>
            }
          >
            {data?.controlExecutionsConnection.edges.map((item) => (
              <HistoryControlsItem controlExecutionsEdge={item} key={item.node.id} />
            ))}
          </InfiniteScroll>
        </List>
      </Content>
    </Root>
  );
};

export default HistoryControlsProperties;
