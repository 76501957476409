import { getBackgroundInput } from '@components/widgets/utils/getBackgroundInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/system';

const CustomSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'settingsStyle' && prop !== 'fgColor',
})(({ fgColor, settingsStyle }) => {
  return {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${fgColor}`,
      borderWidth: '1px',
    },
    '&': {
      backgroundColor: getBackgroundInput(settingsStyle),
      height: '32px',
      borderRadius: '5px',
      padding: '0 !important',

      '& .MuiInputBase-input::-webkit-input-placeholder': {
        color: fgColor,
        opacity: 1,
      },
      '& .MuiInputBase-input': {
        color: fgColor,
        fontSize: '13px',
        padding: '1px 8px !important',
        textAlign: 'left',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${fgColor}`,
        borderWidth: '1px',
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: fgColor,
      },

      '& .MuiSelect-icon': {
        color: fgColor,
        right: '2px',
      },
    },
  };
});

const SelectAggregation = ({ options, settingsStyle, fgColor, value, label, handleChange }) => {
  return (
    <CustomSelect
      MenuProps={{
        style: {
          top: '8px',
          maxHeight: '250px',
        },
      }}
      value={value ? value : ''}
      fgColor={fgColor}
      settingsStyle={settingsStyle}
      size={'small'}
      placeholder={label}
      onChange={handleChange}
    >
      {options?.map((item, index) => (
        <MenuItem key={`option-${index}`} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default SelectAggregation;
