import { gql, useApolloClient } from '@apollo/client';
import AddNewObjectModal from '@components/AddNewObjectModal';
import SelectWidget from '@components/create-widget/SelectWidget';
import LinkWidgetModal from '@components/LinkWidgetModal';
import ObjectListItem from '@components/side-card/ObjectListItem';
import { msg } from '@constants/messages';
import useRoute from '@hooks/useRoute';
import AddIcon from '@mui/icons-material/Add';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { getPropertyByKey, getPropertyValueByKey } from '@utils/getPropertyByKey';
import { isGroup, isMonitoringObject, isStatistics, isWidget } from '@utils/objectType';
import orderBy from 'lodash.orderby';
import { useEffect } from 'react';

const GET_DATA_SUBSCRIPTION = gql`
  subscription Objects($objId: [UUID!]) {
    Objects(filterA: { id: $objId }) {
      event
      relatedNode {
        ... on Object {
          enabled
          name
          id
        }
      }
      relatedNodeId
    }
  }
`;

const TabContentObjects = (props) => {
  const { item } = props;

  const client = useApolloClient();

  const { getParamsByRoute, getTypeByRoute } = useRoute();

  const type = getTypeByRoute();
  const { dashboardId, groupId, widgetId } = getParamsByRoute();

  useEffect(() => {
    const ids = item?.objectsToObjectsByObject1Id?.map((item) => item.object2.id) || [];

    if (ids.length) {
      const observer = client.subscribe({
        query: GET_DATA_SUBSCRIPTION,
        variables: { objId: ids },
      });

      const subscription = observer.subscribe(() => {});

      return () => subscription.unsubscribe();
    }
  }, [item]);

  return (
    <>
      <List>
        {orderBy(item.objectsToObjectsByObject1Id, ['object2.name'], ['asc'])
          .filter((item) => item.object2)
          .map((object, index) => (
            <ObjectListItem
              key={index}
              index={index}
              item={{
                ...object.object2,
                linkId: object.id,
                objectsToObjectsByObject2Id: [
                  {
                    object1: item,
                  },
                ],
              }}
              dashboardId={dashboardId}
              widgetId={widgetId}
              groupId={groupId}
              type={type}
            />
          ))}
        {/* show "+ Add existing object" only for widgets' tab "Objects" */}
        {(isStatistics(item.schemaTags) || isWidget(item.schemaTags) || isMonitoringObject(item.schemaTags)) && (
          <>
            <ListItemButton
              data-test-add-existing-object="addExistingObject"
              onClick={() => {
                LinkWidgetModal({
                  widgetId: item.id,
                  refetch: props.refetch,
                }).catch(() => {});
              }}
            >
              <ListItemIcon>
                <IconButton size="small">
                  <AddIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={msg.sideCard.addExistingObject} />
            </ListItemButton>

            <ListItemButton
              data-test-add-object="addNewObject"
              onClick={() => {
                AddNewObjectModal({
                  refetch: props.refetch,
                  widgetId: item.id,
                }).catch(() => {});
              }}
            >
              <ListItemIcon>
                <IconButton size="small">
                  <AddIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={msg.sideCard.addNewObject} />
            </ListItemButton>
          </>
        )}

        {isGroup(item.schemaTags) && (
          <>
            <ListItemButton
              data-test-new-widget
              onClick={() => {
                SelectWidget({
                  group: {
                    ...item,
                    type: [
                      {
                        id: getPropertyByKey(item.objectProperties, 'generalContainerType').id,
                        value: getPropertyValueByKey(item.objectProperties, 'generalContainerType'),
                      },
                    ],
                    layouts: [
                      {
                        id: getPropertyByKey(item.objectProperties, 'generalLayouts').id,
                        value: getPropertyValueByKey(item.objectProperties, 'generalLayouts'),
                      },
                    ],
                  },
                  groupId: item.id,
                }).catch(() => {});
              }}
            >
              <ListItemIcon>
                <IconButton size="small">
                  <AddIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Add new widget" />
            </ListItemButton>
          </>
        )}
      </List>
    </>
  );
}; //TabContentObjects

export default TabContentObjects;
