import FormField from '@components/FormField';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';

import { IFormValues, validationSchema } from '../../constants/formik';
import st from './style.module.css';

const Parameters: FC<FieldProps<{}, IFormValues>> = ({ form }) => {
  return (
    <Grid item className={st.parametersWrapper}>
      {form?.values?.parameters?.map((item, index) => {
        const field = {
          type: {
            name: item.type,
          },
        };
        return (
          <FormField
            {...fieldRequiredProps(form, validationSchema, `parameters.${index}.value`)}
            isSwitchable={false}
            key={index}
            field={field}
            label={item?.title}
          />
        );
      })}
    </Grid>
  );
};

export default Parameters;
