import { ColorSchemasEnum } from '@constants/constants';
import { BOOLEAN_OPTIONS } from '@modules/widgets/forms/CommandButton/EditControlModal/components/Parameters/constants';
import { BaseTextFieldProps } from '@mui/material';
import { Box } from '@mui/system';
import React, { ChangeEvent, lazy, Suspense } from 'react';
import CustomDate from '../components/CustomDate';
import CustomInput from '../components/CustomInput';
import CustomObjectProperty from '../components/CustomObjectProperty';
import CustomSchemaProperty from '../components/CustomSchemaProperty';
import CustomSelect from '../components/CustomSelect';
import CustomSelectIcon from '../components/CustomSelectIcon';
import CustomSwitch from '../components/CustomSwitch';
import SelectColor from './forms/SelectColor';

const LazyCustomAceEditor = lazy(() => import('@components/CustomJsonInput'));

type FieldType = {
  key?: string;
  description?: string;
  valueSet?: {
    component: string;
    list?: Array<{ title: string; key: string }>;
    filter?: any;
  };
  type?: {
    name: string;
  };
  valueRange?: {
    min?: number;
    max?: number;
  };
};

interface IProps {
  name: string;
  value: any;
  field: FieldType;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  size?: BaseTextFieldProps['size'];
  variant?: BaseTextFieldProps['variant'];
  label?: string;
  isSwitchable?: boolean;
  widgetId?: string;
  colorSchema?: ColorSchemasEnum;
  propId?: string;
  setButtonSaveDisabled?: (state: boolean) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  style?: React.CSSProperties;
}

const FormField = (props: IProps) => {
  const { field } = props;
  const {
    value,
    onChange,
    helperText,
    error,
    required,
    variant,
    isSwitchable = true,
    size,
    colorSchema,
    label = field.description + (!field.description ? `(${field.key})` : ''),
    disabled,
    name = field.key,
    style,
  } = props;

  if (field?.valueSet?.component) {
    switch (field.valueSet.component) {
      case 'select':
        return (
          <CustomSelect
            name={name}
            label={label}
            variant={variant}
            size={size}
            list={field.valueSet.list.map((item) => {
              return { title: item.title, value: item.key };
            })}
            value={value}
            propType={field?.type?.name}
            onChange={onChange}
            disabled={disabled}
          />
        );

      case 'selecticon':
        return (
          <CustomSelectIcon
            name={name}
            label={label}
            variant={variant}
            size={size}
            value={value}
            filter={field.valueSet.filter}
            propType={field?.type?.name}
            onChange={onChange}
            disabled={disabled}
          />
        );

      case 'selectop':
        return (
          <CustomObjectProperty
            name={name}
            label={label}
            variant={variant}
            size={size}
            value={value}
            propType={field?.type?.name}
            onChange={onChange}
            widgetId={props.widgetId}
            propId={props.propId}
            setButtonSaveDisabled={props.setButtonSaveDisabled}
            disabled={disabled}
          />
        );

      case 'selectsp':
        return (
          <CustomSchemaProperty
            name={name}
            label={label}
            variant={variant}
            size={size}
            value={value}
            propType={field?.type?.name}
            onChange={onChange}
            widgetId={props.widgetId}
            propId={props.propId}
            setButtonSaveDisabled={props.setButtonSaveDisabled}
            disabled={disabled}
          />
        );

      case 'date':
        return (
          <CustomDate
            name={name}
            label={label}
            variant={variant}
            size={size}
            value={value}
            propType={field?.type?.name}
            onChange={onChange}
            widgetId={props.widgetId}
            propId={props.propId}
            disabled={disabled}
          />
        );

      case 'select_color':
        return (
          <SelectColor
            name={name}
            label={label}
            variant={variant}
            size={size}
            list={field.valueSet.list.map((item) => {
              return { ...item, title: item.title, value: item.key };
            })}
            value={value}
            propType={field?.type?.name}
            onChange={onChange}
            disabled={disabled}
          ></SelectColor>
        );

      case 'text':
        return (
          <CustomInput
            name={name}
            label={label}
            variant={variant}
            size={size}
            clearFieldIcon={true}
            value={value}
            propType={field?.type?.name}
            onChange={onChange}
            disabled={disabled}
          />
        );

      case 'number':
        return (
          <CustomInput
            name={name}
            label={label}
            variant={variant}
            size={size}
            clearFieldIcon={true}
            value={value}
            onChange={onChange}
            type="number"
            propType={field?.type?.name}
            min={field.valueRange?.min}
            max={field.valueRange?.max}
            disabled={disabled}
          />
        );

      case 'textmulti':
        return (
          <CustomInput
            name={name}
            label={label}
            variant={variant}
            size={size}
            clearFieldIcon={true}
            multiline
            rows={4}
            value={value}
            propType={field?.type?.name}
            onChange={onChange}
            disabled={disabled}
          />
        );

      case 'switch':
        return (
          <Box display="flex" justifyContent="space-between">
            <CustomSwitch
              name={name}
              label={label}
              variant={variant}
              size={size}
              value={value}
              propType={field?.type?.name}
              onChange={onChange}
              disabled={disabled}
            />
          </Box>
        );

      default:
    }
  } // if
  // old approach, based only on type.name (valueSet ignored)
  else {
    switch (field?.type?.name) {
      case 'bool':
        if (isSwitchable) {
          return (
            <Box display="flex" justifyContent="space-between">
              <CustomSwitch
                name={name}
                label={label}
                variant={variant}
                size={size}
                value={value}
                propType={field?.type?.name}
                onChange={onChange}
                disabled={disabled}
              />
            </Box>
          );
        }
        return (
          <CustomSelect
            name={name}
            value={value}
            label={label}
            colorSchema={colorSchema}
            variant={variant}
            size={size}
            onChange={onChange}
            list={BOOLEAN_OPTIONS}
            propType={field?.type?.name}
          />
        );
      case 'string':
        return (
          <CustomInput
            name={name}
            label={label}
            variant={variant}
            size={size}
            colorSchema={colorSchema}
            clearFieldIcon={true}
            value={value}
            propType={field?.type?.name}
            disabled={disabled}
            onChange={onChange}
          />
        );
      case 'number':
        return (
          <CustomInput
            name={name}
            label={label}
            variant={variant}
            size={size}
            colorSchema={colorSchema}
            clearFieldIcon={true}
            value={value}
            type="number"
            propType={field?.type?.name}
            min={field.valueRange?.min}
            max={field.valueRange?.max}
            onChange={onChange}
            disabled={disabled}
          />
        );
      case 'json':
      case 'json array':
      case 'json object':
        return (
          <Suspense fallback={null}>
            <LazyCustomAceEditor
              name={name}
              label={label}
              variant={variant}
              size={size}
              style={style}
              value={value}
              colorSchema={colorSchema}
              helperText={helperText}
              required={required}
              error={error}
              onChange={onChange}
            />
          </Suspense>
        );
    }
  }
};

export default React.memo(FormField);
