import { OptionValue } from '@components/common/ImageItem/components/MediaAutocomplete';
import CustomAutocomplete from '@components/CustomAutocomplete';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import { IFormValues, validationSchema } from '../constants';

export const Property = ({
  form,
  label,
  field,
  list,
}: FieldProps<IFormValues> & { label: string; list: OptionValue[] }) => {
  return (
    <Grid item>
      <CustomAutocomplete
        {...fieldRequiredProps(form, validationSchema, field.name)}
        label={label}
        list={list}
        value={form.values[field.name].value}
        onChange={(e) => {
          if (!e.target.value) {
            form.setFieldValue(field.name, {
              value: null,
              title: 'n/a',
            });
          } else {
            form.setFieldValue(field.name, {
              value: e.target.rawValue.id || '',
              title: e.target.rawValue.title || e.target.rawValue.property,
            });
          }
        }}
        required={true}
      />
    </Grid>
  );
};
