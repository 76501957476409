import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import { IFormValues, validationSchema } from '../constants';

export const Alias = ({ form, label, field }: FieldProps<IFormValues> & { label: string }) => (
  <Grid item>
    <CustomInput {...fieldRequiredProps(form, validationSchema, field.name)} label={label} clearFieldIcon={true} />
  </Grid>
);
