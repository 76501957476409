import { validationSchema } from '@components/EditObjectPropertyGroup/constants';
import FormField from '@components/FormField';
import { ObjectProperty } from '@src/__generated__/graphql';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

interface IProps {
  property: Partial<ObjectProperty>;
  widgetId?: string;
}

const DynamicFiled = ({ form, property, widgetId, field }: FieldProps & IProps) => {
  const { key: name, type, spec, id } = property;
  const formField = {
    type: {
      name: type,
    },
    description: spec?.description,
    key: name,
    valueSet: spec?.valueSet,
  };

  return (
    <FormField
      {...fieldRequiredProps(form, validationSchema, field.name)}
      isSwitchable={false}
      field={formField}
      widgetId={widgetId}
      propId={id}
    />
  );
};

export default DynamicFiled;
