import { useMutation } from '@apollo/client';
import CommonModal from '@components/CommonModal';
import { msg } from '@constants/messages';
import { UPDATE_PROPERTY } from '@modules/objects/api/updateProperty';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ObjectProperty } from '@src/__generated__/graphql';
import { FastField } from 'formik';
import cloneDeep from 'lodash.clonedeep';
import differenceBy from 'lodash.differenceby';
import { useMemo } from 'react';
import { create, InstanceProps } from 'react-modal-promise';

import { Alias } from './components/Alias';
import { Property } from './components/Property';
import { Value } from './components/Value';
import { validationSchema } from './constants';

export interface ColumnValueType {
  order: number;
  alerts: unknown[];
  property: {
    id: string;
    alias: string;
    name: string;
    type: string;
  };
}

interface IProps {
  isEdit: boolean;
  properties: ObjectProperty[];
  item: ColumnValueType;
  columnProperty: ObjectProperty;
}

const MonitorTableColumnModal = ({
  isEdit,
  properties,
  item,
  columnProperty,
  onResolve,
  onReject,
  isOpen,
}: IProps & InstanceProps<{}>) => {
  const columns: ColumnValueType[] = columnProperty.value.columns;

  const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY);

  const handleEditProperty = (value) => {
    void updateProperty({
      variables: {
        input: {
          id: columnProperty.id,
          patch: {
            value,
          },
        },
      },
    }).then(() => {
      onResolve();
    });
  };

  const formik = useMemo(
    () => ({
      initialValues: {
        value: item?.property?.type ?? 'value',
        property: {
          value: item?.property?.id || null,
          title: item?.property?.name || 'n/a',
        },
        alias: item?.property?.alias ?? '',
      },
      validationSchema,
      onSubmit: ({ alias, property, value: typeColumn }) => {
        const oldValues = cloneDeep(columns);

        if (isEdit) {
          const updatedValues = oldValues.map((value) =>
            value.property.id === item.property.id
              ? {
                  alerts: item.alerts,
                  order: item?.order || 0,
                  property: {
                    type: typeColumn,
                    alias,
                    name: property.title,
                    id: property.value,
                  },
                }
              : value
          );
          const patch = {
            ...columnProperty.value,
            columns: updatedValues,
          };
          handleEditProperty(patch);
        } else {
          oldValues.push({
            alerts: [],
            order: oldValues.length,
            property: {
              type: typeColumn,
              alias,
              name: property.title,
              id: property.value,
            },
          });
          const patch = {
            ...columnProperty.value,
            columns: [...oldValues],
          };

          handleEditProperty(patch);
        }
      },
    }),
    [item, isEdit]
  );

  const propertiesList = useMemo(
    () =>
      differenceBy(
        properties,
        columns.map((column) => column.property).filter((column) => column.id !== item?.property?.id),
        'id'
      ),
    [properties, columns]
  );

  return (
    <CommonModal
      key="MonitorTableColumnModal"
      modalOpen={isOpen}
      title={isEdit ? 'Edit column' : 'Add column'}
      handleClose={onResolve}
      isForm={true}
      formik={formik}
      buttons={
        <>
          <Button color="inherit" onClick={onReject}>
            {msg.default.cancel}
          </Button>
          <Button color="primary" type="submit">
            {loading ? <CircularProgress size={23} /> : isEdit ? msg.default.save : msg.default.add}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={1}>
        <FastField name="property" label="Property" list={propertiesList} component={Property} />
        <FastField name="value" label="Value" component={Value} />
        <FastField name="alias" label="Custom name" component={Alias} />
      </Grid>
    </CommonModal>
  );
};

export default create(MonitorTableColumnModal);
