import CloseIcon from '@mui/icons-material/Close';
import { FormControl, FormHelperText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/system';
import { castToType, checkNullToString } from '@utils/misc.js';
import useColors from '@utils/useColors';
import { useMemo } from 'react';

import st from './style.module.css';

const CustomSelect = (props) => {
  const { getFieldStyleBasedOnColorSchema } = useColors();
  const theme = useTheme();
  const colorSchemaMap = useMemo(
    () => getFieldStyleBasedOnColorSchema({ colorSchema: props.colorSchema, field: 'customSelect' }),
    [props.colorSchema, theme.palette.mode]
  );
  const handleInputChangeCasted = (event, type) => {
    let { name, value, checked } = event.target;

    let e = {
      target: { name: name, value: castToType(value, type), checked: checked },
    };

    if (typeof value === 'object' && !Array.isArray(value)) {
      e = { target: { name: name, value: value.value, checked: undefined } };
    }

    props.onChange(e);
  };

  const value = props.value?.value ? props.value.value : checkNullToString(props.value, props.propType);

  return (
    <>
      <FormControl
        sx={props.colorSchema ? colorSchemaMap : null}
        fullWidth
        variant={props.variant ?? 'standard'}
        error={props.error}
      >
        <InputLabel id={`${props.name}-label`} required={props.required}>
          {props.label}
        </InputLabel>
        <Select
          MenuProps={{
            style: {
              maxHeight: '500px',
            },
          }}
          data-test-select={props['data-test'] || props.name}
          name={props.name}
          size={props.size}
          value={value}
          labelId={`${props.name}-label`}
          label={props.label}
          disabled={Boolean(props.disabled)}
          onChange={(e) => {
            handleInputChangeCasted(e, props.propType);
          }}
          {...(props.clearFieldIcon && props.value !== '' && props.value !== null
            ? { classes: { icon: st.icon, select: st.selectSelect } }
            : {})}
          endAdornment={
            Boolean(props.clearFieldIcon) &&
            props.value !== '' &&
            props.value !== null && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="reset"
                  onClick={() => props.onChange({ target: { name: props.name, value: '' } })}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          {props.list?.map((item, index) => (
            <MenuItem
              key={`item-${item.value}-${index}`}
              data-test-select-value={item.value}
              style={{ height: '48px' }}
              disabled={item.disabled}
              value={checkNullToString(item.value, props.propType)}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
        {props.helperText && <FormHelperText id="my-helper-text">{props.helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default CustomSelect;
