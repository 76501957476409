import { ScaleOptionsEnum } from '@components/create-widget/regular-chart/constants';
import {
  SettingsSizeOptions,
  SettingsStyleOptions,
  SmoothTypeEnum,
  TypeOptionsEnum,
} from '@utils/constants/selectOptions';
import * as yup from 'yup';

type TDataChartColorState = {
  settingsTitle: boolean;
  settingsTitleSecondary: boolean;
  settingsSimulation: boolean;
  settingsStyle: SettingsStyleOptions;
  settingsSize: SettingsSizeOptions;
  settingsChartType: TypeOptionsEnum;
  settingsSmoothType: SmoothTypeEnum;
  settingsYaxisScale?: ScaleOptionsEnum;
  settingsLineWidth: 2 | 4 | 6 | 8;
  settingsFill: boolean;
  settingsMaximum: number;
  settingsMinimum: number;
};

export type CommonFieldProps = {
  label: string;
  type?: string;
  list?: { title: string; value: string }[];
  clearFieldIcon?: boolean;
  multiline?: boolean;
};

export type IFormValues = {
  name: string;
  description?: string;
  properties?: TDataChartColorState;
};

export const DEFAULT_DATACHART_COLOR_VALUES: TDataChartColorState = {
  settingsTitle: false,
  settingsTitleSecondary: false,
  settingsSimulation: false,
  settingsStyle: SettingsStyleOptions.lightondark,
  settingsSize: SettingsSizeOptions.medium,
  settingsChartType: TypeOptionsEnum.line,
  settingsSmoothType: SmoothTypeEnum.smooth,
  settingsLineWidth: 2,
  settingsYaxisScale: ScaleOptionsEnum.auto,
  settingsMaximum: 100,
  settingsMinimum: 0,
  settingsFill: false,
};

export const validationSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  description: yup.string().trim(),
  properties: yup.object().shape({
    settingsTitle: yup.boolean(),
    settingsTitleSecondary: yup.boolean(),
    settingsSimulation: yup.boolean(),
    settingsStyle: yup.mixed<SettingsStyleOptions>().oneOf(Object.values(SettingsStyleOptions)),
    settingsSize: yup.mixed<SettingsSizeOptions>().oneOf(Object.values(SettingsSizeOptions)),
    settingsChartType: yup.mixed<TypeOptionsEnum>().oneOf(Object.values(TypeOptionsEnum)),
    settingsSmoothType: yup.mixed<SmoothTypeEnum>().oneOf(Object.values(SmoothTypeEnum)),
    settingsLineWidth: yup.number().oneOf([2, 4, 6, 8]),
    settingsFill: yup.boolean(),
    settingsYaxisScale: yup.string().required('Type is required'),
    settingsMinimum: yup.number().when('settingsYaxisScale', {
      is: (type: ScaleOptionsEnum) => type === ScaleOptionsEnum.fixed,
      then: yup.number().required('Min is required'),
      otherwise: yup.number().notRequired(),
    }),
    settingsMaximum: yup.number().when('settingsYaxisScale', {
      is: (type: ScaleOptionsEnum) => type === ScaleOptionsEnum.fixed,
      then: yup.number().required('Max is required').moreThan(yup.ref('settingsMinimum'), 'Must be greater than min'),
      otherwise: yup.number().notRequired(),
    }),
  }),
});
