import { useSubscription } from '@apollo/client';
import { CloudOffOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/system';

import WidgetInitInfo from '@components/common/WidgetInitInfo';
import { ScaleOptionsEnum } from '@components/create-widget/regular-chart/constants';
import WidgetEditControls from '@components/WidgetEditControls';
import { ColorSchemasEnum, COLOR_SCHEMAS, highlightSelectedStyle } from '@constants/constants';
import { ObjectProperty } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import hideIfEmpty from '@utils/hideIfEmpty';
import useColors from '@utils/useColors';
import { useInterval } from '@utils/useTimer';
import ReactEChartsCore from 'echarts-for-react';
import { LineChart } from 'echarts/charts';
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { round } from 'mathjs';
import { useEffect, useMemo, useState } from 'react';
import { TPoint } from './charts';
import ChartSubTitle from './ChartSubTitle';
import ChartSummary from './ChartSummary';
import ChartTitle from './ChartTitle';
import { axisLabelFormatter, CHART_STATUES, rndRange, simulateData } from './constants';
import { SUBSCRIBE_TO_OBJECT } from './SubscribeToObject';

type TChartConditions = {
  condition1: {
    color: string;
    min: number;
    max: number;
  };
  condition2: {
    color: string;
    min: number;
    max: number;
  };
  condition3: {
    color: string;
    min: number;
    max: number;
  };
  condition4: {
    color: string;
    min: number;
    max: number;
  };
};

const DataColoChartWidget = (props: {
  id?: string;
  title?: string;
  name?: string;
  selected?: boolean;
  objectProperties?: ObjectProperty[];
}) => {
  const { id, objectProperties, selected } = props;

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    DataZoomComponent,
  ]);

  const theme = useTheme();
  const objectPropertiesMap = useMemo(() => getPropertiesMap(objectProperties), [objectProperties]);
  const getPropValue = (prop: string): unknown => objectPropertiesMap[prop]?.value;
  const getProp = (prop: string): unknown => objectPropertiesMap[prop];
  const size = getPropValue('settingsSize') as string;
  const wide = getPropValue('settingsFullLine');
  const [chartInstance, setChartInstance] = useState<{
    clear: () => void;
    resize: () => void;
  } | null>(null);
  const [styleChart1Label, setStyleChart1Label] = useState(getPropValue('styleChart1Label'));

  const [styleChartYaxisLabel, setStyleChartYaxisLabel] = useState(getPropValue('styleY-AxisLabel') as string);
  const [lineWidth, setLineWidth] = useState((getPropValue('settingsLineWidth') as string) || '2');
  const [isShowTitle, setIsShowTitle] = useState(getPropValue('settingsTitle') || false);
  const [isShowSubTitle, setIsShowSubTitle] = useState(getPropValue('settingsTitleSecondary') || false);

  let valueInitial: TPoint[] = [];

  const simulation = getPropValue('settingsSimulation');
  const chart1ConditionsDefault = getPropValue('chart1Conditions') as TChartConditions;
  const settingsTimeInterval: string = getPropValue('settingsTimeInterval') as string;

  if (!simulation) {
    if (getPropValue('valueChart1')) {
      if (Array.isArray(getPropValue('valueChart1'))) {
        valueInitial = getPropValue('valueChart1') as TPoint[];
      }
    }
  }

  const fill = getPropValue('settingsFill') as boolean;
  const smoothType = getPropValue('settingsSmoothType');
  const type = getPropValue('settingsChartType') as string;
  const style = getPropValue('settingsStyle') as ColorSchemasEnum;
  const isAutoScale = getPropValue('settingsYaxisScale') === ScaleOptionsEnum.auto;
  const settingsMaximum = getPropValue('settingsMaximum');
  const settingsMinimum = getPropValue('settingsMinimum');
  const { getColorBasedOnStyle } = useColors();

  const [{ fg: fgColor, bg: bgColor }, setColorsBasedOnStyle] = useState(
    getColorBasedOnStyle(getPropValue('settingsStyle') as ColorSchemasEnum)
  );

  const mapColorByName = (color: string): string => {
    if (!color) return 'default';
    const colors = getColorBasedOnStyle(getPropValue('settingsStyle') as ColorSchemasEnum);

    const colorsMap = {
      red: { color: theme.palette.wRed },
      deepRed: { color: theme.palette.wDeepRed },
      green: { color: theme.palette.wGreen },
      orange: { color: theme.palette.wOrange },
      yellow: { color: theme.palette.wYellow },
      yellowGreen: { color: theme.palette.wYellowGreen },
      blue: { color: theme.palette.wBlue },
      black: { color: theme.palette.wBlack },
      white: { color: theme.palette.wWhite },
      default: { color: colors.fg },
      gray3: { color: theme.palette.gray3 },
    };

    if (color?.includes('#')) {
      return colorsMap.default.color;
    }

    return colorsMap[color].color as string;
  };

  const [styleChart1Color, setStyleChart1Color] = useState(mapColorByName(getPropValue('styleChart1Color') as string));

  const [settingsShowTable, setSettingsShowTable] = useState(getPropValue('settingsShowTable'));

  const [settingsTableColumns, setSettingsTableColumns] = useState(getPropValue('settingsTableColumns'));
  const [chart1Conditions, setChart1Conditions] = useState(chart1ConditionsDefault);

  const timerSimulation = useInterval();

  const [value, setValue] = useState(valueInitial);

  const [chartStatus, setChartStatus] = useState('');

  const setSimulatedData = () => {
    const { simulatedData } = simulateData();

    setValue(simulatedData);
  };

  const setData = () => {
    setValue((prevState) => [
      ...prevState.filter((item, index) => index !== 0),
      {
        x: prevState.length ? prevState[prevState.length - 1].x + 3600 * 1000 : 3600 * 1000,
        y: rndRange(20, 30),
      },
    ]);
  };

  const colors = [getPropValue('settingsStyle'), null];

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return '';
    }

    const themeLocal = colors[0];

    switch (themeLocal) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return '#F1F1F1';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  };

  useEffect(() => {
    if (simulation) {
      if (timerSimulation.current) {
        clearInterval(timerSimulation.current);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timerSimulation.current = setInterval(setData, 1000);
      setSimulatedData();
    } else {
      if (chartInstance?.clear) {
        chartInstance?.clear();
        chartInstance?.resize();
      }
      setValue(valueInitial);
      if (timerSimulation.current) {
        clearInterval(timerSimulation.current);
      }
    }
  }, [simulation, type]);

  useEffect(() => {
    setColorsBasedOnStyle(getColorBasedOnStyle(style));
    setStyleChart1Color(mapColorByName(getPropValue('styleChart1Color') as string));
  }, [style, theme.palette.mode]);

  const resizeChart = () => {
    if (chartInstance) {
      setTimeout(() => {
        chartInstance.resize();
      }, 100);
    }
  };

  const checkIsConfigured = () => {
    const values = [getPropValue('valueChart1')];
    const configured = [getPropValue('chart1Property')];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!configured.some((item) => item?.value) && values.every((item) => !item.length)) {
      setChartStatus(CHART_STATUES.NO_CONFIGURED);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (configured.some((item) => item?.value) && values.every((item) => !item.length)) {
      setChartStatus(CHART_STATUES.CONFIGURED_NO_DATA);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (configured.some((item) => item.value) && values.some((item) => item.length)) {
      setChartStatus(CHART_STATUES.CONFIGURED);
    }

    resizeChart();
  };

  const subscribeHandler = {
    default: () => {},
    valueChart1: (incomingValue: unknown) => {
      if (Array.isArray(incomingValue) && !simulation) {
        const valueChart: TPoint[] = incomingValue || [];
        setValue(
          valueChart.map((v: TPoint): TPoint => {
            return { ...v, id: `${v.x}_${v.y}` };
          })
        );
      }
    },
    styleChart1Color: (incomingValue: string) => {
      setStyleChart1Color(mapColorByName(incomingValue));
    },
    styleChart1Label: (incomingValue) => {
      setStyleChart1Label(incomingValue);
    },
    'styleY-AxisLabel': (incomingValue: string) => {
      setStyleChartYaxisLabel(incomingValue);
    },
    settingsTableColumns: (incomingValue) => {
      setSettingsTableColumns(incomingValue);
    },
    settingsLineWidth: (incomingValue: string) => {
      setLineWidth(incomingValue);
    },
    settingsShowTable: (incomingValue) => {
      setSettingsShowTable(incomingValue);
      resizeChart();
    },
    settingsTitleSecondary: (incomingValue: boolean) => {
      setIsShowSubTitle(incomingValue);
      resizeChart();
    },
    settingsTitle: (incomingValue: boolean) => {
      setIsShowTitle(incomingValue);
      resizeChart();
    },
    chart1Conditions: (incomingValue: TChartConditions) => {
      setChart1Conditions(incomingValue);

      // chartInstance.setOption()
      chartInstance?.clear();
      resizeChart();
    },
  };

  useSubscription(SUBSCRIBE_TO_OBJECT, {
    variables: { objId: [id] },
    onData: ({
      data: {
        data: {
          Objects: { relatedNode },
        },
      },
    }) => {
      if (relatedNode?.__typename === 'ObjectProperty') {
        const fn: (value: unknown) => void = subscribeHandler[relatedNode?.key];
        const fnDefault: () => void = subscribeHandler.default;

        if (!fn) {
          fnDefault();
        } else {
          fn(relatedNode?.value);
        }
        checkIsConfigured();
      }
    },
  });

  useEffect(() => {
    checkIsConfigured();
  }, [id, chartInstance]);

  let areaStyleOpacity = 0;

  if (fill) areaStyleOpacity = 0.7;

  let step, smooth;

  switch (smoothType) {
    case 'linear':
      step = '';
      smooth = false;
      break;

    case 'stepwise':
      step = 'start';
      smooth = true;
      break;

    case 'smooth':
      step = '';
      smooth = true;
      break;
  }

  const getSubtitle = () => {
    interface ISpec {
      spec: {
        valueSet: {
          list: { title: string; key: string }[];
        };
      };
    }

    const prop = getProp('settingsTimeInterval') as ISpec;
    const groupBy = getProp('chart1GroupBy') as ISpec;
    const groupingFunction = getProp('chart1GroupingFunction') as ISpec;

    const timeInterval =
      prop.spec.valueSet.list.find((item) => item.key === (getPropValue('settingsTimeInterval') as string))?.title ||
      '';

    const groupByString =
      groupBy.spec.valueSet.list.find((item) => item.key === (getPropValue('chart1GroupBy') as string))?.title || '';

    const groupingFunctionString =
      groupingFunction.spec.valueSet.list.find(
        (item) => item.key === (getPropValue('chart1GroupingFunction') as string)
      )?.title || '';

    let string = '';

    string += `Interval: ${timeInterval}`;

    if (groupByString) {
      string += ` | Group by: ${groupByString}`;
    }

    if (groupingFunctionString) {
      string += ` | Function: ${groupingFunctionString}`;
    }

    return string;
  };

  const series = [
    {
      data: value,
      color: styleChart1Color,
      label: styleChart1Label,
    },
  ];

  const isAnyPieces = () => {
    return Object.values(chart1Conditions)
      .map((item) => Boolean(item.max))
      .every((item) => item === false);
  };

  const getPieces = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [
      ...hideIfEmpty(typeof chart1Conditions.condition1?.max === 'number', [
        {
          min: Number(chart1Conditions.condition1.min),
          max: Number(chart1Conditions.condition1.max),
          color: mapColorByName(chart1Conditions.condition1.color),
        },
      ]),
      ...hideIfEmpty(typeof chart1Conditions.condition2?.max === 'number', [
        {
          min: Number(chart1Conditions.condition1?.min),
          max: Number(chart1Conditions.condition2.max),
          color: mapColorByName(chart1Conditions.condition2.color),
        },
      ]),
      ...hideIfEmpty(typeof chart1Conditions.condition3?.min === 'number', [
        {
          min: Number(chart1Conditions.condition2?.min),
          max: Number(chart1Conditions.condition3.max),
          color: mapColorByName(chart1Conditions.condition3.color),
        },
      ]),
      ...hideIfEmpty(typeof chart1Conditions.condition4?.min === 'number', [
        {
          min: Number(chart1Conditions.condition3?.min),
          max: Number(chart1Conditions.condition4.max),
          color: mapColorByName(chart1Conditions.condition4.color),
        },
      ]),
    ];
  };

  const chartOptions = () => {
    const config = {
      animation: false,
      color: fgColor,
      grid: {
        top: 40,
        right: 15,
        bottom: 8,
        left: 8,
        containLabel: true,
      },
      xAxis: {
        animationThreshold: 500,
        show: size !== 'small',
        type: 'time',
        axisTick: {
          lineStyle: { color: fgColor, opacity: 0.3 },
          show: true,
        },
        splitLine: {
          lineStyle: { color: fgColor, opacity: 0.3 },
          show: true,
        },
        nameLocation: 'center',
        nameTextStyle: {
          align: 'center',
        },
        axisLabel: {
          hideOverlap: true,
          formatter(valueAxis: number) {
            return axisLabelFormatter(valueAxis, settingsTimeInterval);
          },
        },
        axisLine: {
          lineStyle: { color: fgColor, opacity: 0.3 },
          show: true,
        },
      },
      barMaxWidth: 40,
      barMinWidth: 10,
      yAxis: {
        min: isAutoScale ? undefined : settingsMinimum,
        max: isAutoScale ? undefined : settingsMaximum,
        animationThreshold: 500,
        nameLocation: 'center',
        axisLabel: {
          formatter: `{value} ${styleChartYaxisLabel || ''}`,
        },
        type: 'value',
        axisTick: {
          lineStyle: { color: fgColor, opacity: 0.3 },
          show: true,
        },
        splitLine: {
          lineStyle: { color: fgColor, opacity: 0.3 },
          show: true,
        },
        scale: true,
        axisLine: {
          lineStyle: { color: fgColor, opacity: 0.3 },
          show: true,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          zoomOnMouseWheel: 'ctrl',
        },
      ],
      textStyle: {
        color: fgColor,
      },
      legend: {
        show: false,
      },
      series: series.map((item) => ({
        data: item.data.map((point) => {
          if (point.x || point.y) {
            return {
              id: `${point.x}_${point.y}`,
              value: [point.x, point.y],
            };
          } else {
            return {};
          }
        }),
        type,
        lineStyle: {
          width: lineWidth,
          color: isAnyPieces() ? item.color : undefined,
        },
        smooth,
        step,
        name: item.label,
        areaStyle: {
          opacity: areaStyleOpacity,
          color: isAnyPieces() ? item.color : undefined,
        },
        emphasis: {
          lineStyle: {
            color: isAnyPieces() ? item.color : undefined,
          },
          areaStyle: {
            color: isAnyPieces() ? item.color : undefined,

            opacity: areaStyleOpacity,
          },
        },
        itemStyle: { color: isAnyPieces() ? item.color : undefined },
      })),
      tooltip: {
        triggerOn: 'click',
        trigger: 'axis',
        confine: true,
      },
      visualMap: undefined,
    };

    if (!isAnyPieces()) {
      config.visualMap = {
        show: !isAnyPieces(),
        type: 'piecewise',
        top: 8,
        right: 15,
        precision: 1,
        itemGap: 20,
        hoverLink: false,
        textGap: 5,
        textStyle: {
          fontSize: '14',
          fontWeight: 'bold',
          color: fgColor,
        },
        formatter: (v1: number, v2: number) => {
          return `${round(v1, 2)}—${round(v2, 2)}`;
        },
        itemSymbol: 'circle',
        orient: 'horizontal',
        pieces: getPieces(),
        outOfRange: {
          color: '#999',
        },
      };
    } else {
      config.grid.top = 20;
      config.visualMap = undefined;
    }

    return config;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexBasis: wide ? '100%' : 'auto',
          flexGrow: 1,
          position: 'relative',
          height: `100%`,
          backgroundColor: bgColor,
          overflow: 'hidden',
          filter: selected ? highlightSelectedStyle : '',
          borderRadius: '2px',
        }}
      >
        {isShowTitle && <ChartTitle fgColor={fgColor}>{props.name}</ChartTitle>}
        {value.length > 0 && (
          <>
            {isShowSubTitle && <ChartSubTitle fgColor={fgColor}>{getSubtitle()}</ChartSubTitle>}

            {chartOptions && (
              <ReactEChartsCore
                echarts={echarts}
                option={chartOptions()}
                onChartReady={setChartInstance}
                lazyUpdate={true}
                style={{ width: '100%', height: '100%' }}
              />
            )}

            {settingsShowTable && (
              <ChartSummary
                settingsTableColumns={settingsTableColumns}
                fgColor={fgColor}
                bgColor={bgColor}
                value={value}
                getColorOfRow={getColorOfRow}
                styleChart1Label={styleChart1Label || (getPropValue('chart1Property') as { title: string })?.title}
              />
            )}
          </>
        )}
        {chartStatus === CHART_STATUES.NO_CONFIGURED && !simulation && (
          <WidgetInitInfo fgColor={fgColor} infoText={'Make sure you configured chart'} icon={undefined} />
        )}
        {chartStatus === CHART_STATUES.CONFIGURED_NO_DATA && !simulation && (
          <WidgetInitInfo
            icon={<CloudOffOutlined fontSize={'large'} />}
            fgColor={fgColor}
            mainText={'No data for selected period'}
            infoText={undefined}
          />
        )}
        <WidgetEditControls {...props} />
      </div>
    </>
  );
};

export default DataColoChartWidget;
