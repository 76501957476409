// eslint-disable-next-line import/named
import isFinite from 'lodash.isfinite';
import sortBy from 'lodash.sortby';
import toNumber from 'lodash.tonumber';
// eslint-disable-next-line import/named
import { max, mean, median, min, round, sum } from 'mathjs';
import { TPoint } from './charts';

const isNumeric = (value: unknown): boolean => {
  if (value === '') return false;
  if (value === null) return false;
  if (value === undefined) return false;
  return isFinite(toNumber(value));
};

export const getAverage = (arr: TPoint[]) => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === 'number' || isNumeric(item.y);
    })
    .map((item) => Number(item.y));

  if (!nums.length) return '';

  return round(mean(nums), 2);
};

export const getMedian = (arr: TPoint[]) => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === 'number' || isNumeric(item.y);
    })
    .map((item) => Number(item.y));

  if (!nums.length) return '';

  return round(median(nums), 2);
};

export const getCurrent = (arr: TPoint[]) => {
  if (!arr.length) return '';

  const last = sortBy(arr, 'x')[arr.length - 1];

  if (typeof last?.y === 'number' || isNumeric(last?.y)) {
    return round(Number(last?.y), 2);
  } else {
    return `${last.y}`;
  }
};

export const getMin = (arr: TPoint[]) => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === 'number' || isNumeric(item.y);
    })
    .map((item) => Number(item.y));

  if (!nums.length) return '';

  return round(min(nums), 2);
};

export const getMax = (arr: TPoint[]) => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === 'number' || isNumeric(item.y);
    })
    .map((item) => Number(item.y));

  if (!nums.length) return '';

  return round(max(nums), 2);
};

export const getSum = (arr: TPoint[]) => {
  const nums = arr
    .filter((item) => {
      return typeof item.y === 'number' || isNumeric(item.y);
    })
    .map((item) => Number(item.y));

  if (!nums.length) return 0;

  return round(sum(nums), 2);
};
