import { cloneElement, useState } from 'react';
import ConfirmRpcExecution from '../../modals/ConfirmRpcExecution';
import RpcProcessModal from '../../modals/RpcProcessModal';

const RpcSubscribeWrapper = (props) => {
  const [loading, setLoading] = useState(false);

  const rpcCall = () => {
    if (!props.rpcName) {
      return false;
    }
    if (props.isConfirm) {
      ConfirmRpcExecution({
        handleSave: () => {
          setLoading(true);
          RpcProcessModal({
            rpcName: props.rpcName,
            id: null,
            callRpc: props.handler,
            objectId: props.objectId,
            successCb: props.successCb,
          }).finally(() => {
            setLoading(false);
          });
        },
        text: 'Execute action?',
      })
        .then()
        .catch(() => {
          setLoading(false);
        });
    } else {
      RpcProcessModal({
        rpcName: props.rpcName,
        id: null,
        callRpc: props.handler,
        objectId: props.objectId,
        successCb: props.successCb,
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  return cloneElement(props.children, {
    onClick: rpcCall,
    disabled: Boolean(props.disabled || !props.rpcName),
    loading,
    ...(props?.title ? { children: props.title } : null),
  });
};

export default RpcSubscribeWrapper;
