import { useMutation } from '@apollo/client';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import RpcSubscribeWrapper from '@components/side-card/static-table/RpcSubscribeWrapper';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import { getPropertyByKey } from '@utils/getPropertyByKey';
import { useEffect, useState } from 'react';

import tabStyle from '@components/side-card/tab.module.css';
import GeotagsSection from './geotags/GeotagsSection';
import ParametersSection from './ParametersSection';
import PeriodsSection from './periods/PeriodsSection';

const TrackingTableGeneral = (props) => {
  const { item } = props;
  const [createExecution] = useMutation(CREATE_CONTROL_EXECUTION);

  const getProperty = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const settingsPeriodsProperty = () => {
    return getPropertyByKey(item.objectProperties, 'settingsPeriods');
  };

  const settingsGeotagsProperty = () => {
    return getPropertyByKey(item.objectProperties, 'settingsGeotags');
  };

  const parametersMinimumDuration = () => {
    return getPropertyByKey(item.objectProperties, 'parametersMinimumDuration');
  };

  const parametersNoMotionTimeout = () => {
    return getPropertyByKey(item.objectProperties, 'parametersNoMotionTimeout') || { value: 0 };
  };

  const [isGetDataDisabled, setIsGetDataDisabled] = useState(true);

  useEffect(() => {
    if (item) {
      const conditions = [
        getProperty('settingsGeotags')?.value?.length,
        getProperty('settingsPeriods')?.value?.length,
        getProperty('parametersDateRange')?.value?.length,
      ];

      setIsGetDataDisabled(!conditions.every((item) => item));
    }
  }, [item]);

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'UpdateReport',
            objectId: item.id,
            params: {
              minimum_duration: parametersMinimumDuration().value,
              no_motion_timeout: parametersNoMotionTimeout().value || 0,
            },
          },
        },
      },
    });
  };

  return (
    <>
      <List>
        <ParametersSection classes={tabStyle} item={item} />
        <GeotagsSection geotagsProperty={settingsGeotagsProperty()} classes={tabStyle} item={item} />
        <PeriodsSection periodsProperty={settingsPeriodsProperty()} classes={tabStyle} />
        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={'UpdateReport'}
            objectId={item.id}
            disabled={isGetDataDisabled}
            object={item}
            handler={rpcHandler}
            title={'Get data'}
          >
            <LoadingButton fullWidth={true} variant={'outlined'}></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>
        <ServiceSection classes={tabStyle} item={item} />
        <AccessSection classes={tabStyle} item={item} />
        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default TrackingTableGeneral;
