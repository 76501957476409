import * as yup from 'yup';

export interface IFormValues {
  control: string;
  widgetId: string;
  parameters: { title: string; value: string; argument: string }[];
  propKey: string;
}

export const validationSchema = yup.object({
  control: yup.string().trim(),
});
