import { useApolloClient } from '@apollo/client';
import EmptyTableWithColumns from '@components/common/EmptyTableWithColumns';
import EmptyTableWithoutColumns from '@components/common/EmptyTableWithoutColumns';
import WidgetEditControls from '@components/WidgetEditControls';
import { COLOR_SCHEMAS, highlightSelectedStyle } from '@constants/constants';
import { GET_DATA_SUBSCRIPTION } from '@graphql/queries';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useColors from '@utils/useColors';
import { BASE_HEIGHT } from '@utils/widgetSizes';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import st from './style.module.css';

const W_TrackingTable = (props) => {
  const { objectProperties, selected } = props;
  const client = useApolloClient();

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;
  const [valueInitial, setValue] = useState(objectProperties.find((obj) => obj.key === 'valueValue')?.value);
  const periods = objectProperties.find((item) => item.key === 'settingsPeriods')?.value || [];
  const units = objectProperties.find((item) => item.key === 'parametersFunctionUnits')?.value || '';
  const colors = [getPropValue('settingsStyle'), null];
  const showTitle = getPropValue('settingsShowTitle');
  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue('settingsStyle'));

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return '';
    }

    const theme = colors[0];

    switch (theme) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return '#F1F1F1';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: props.id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === 'valueValue') {
        setValue(data.Objects.relatedNode?.value);
      }
    });

    return () => subscription.unsubscribe();
  }, [props.id]);

  const getCorrectCell = (periods, targetPeriod) => {
    const period = periods.find((item) => item.id === targetPeriod.id)?.value ?? '';

    if (units) {
      return `${period} ${units}`;
    }

    return period;
  };

  return (
    <div
      className={'force-scroll'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : '',
        borderRadius: '2px',
      }}
    >
      <div style={{ width: '100%' }}>
        {periods.length > 0 && valueInitial.length > 0 && (
          <Table size="small" className={st.table} aria-label="simple table" style={{ backgroundColor: 'transparent' }}>
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: bgColor,
              }}
            >
              {showTitle && (
                <TableRow
                  style={{
                    height: `${BASE_HEIGHT}px`,
                  }}
                >
                  <TableCell className={st.tableCellHead} align="center" variant="head" colSpan={periods?.length + 2}>
                    <Typography style={{ color: fgColor }} variant="h6">
                      {props.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                }}
              >
                <TableCell className={st.tableCellHead} style={{ textAlign: 'left', color: fgColor }}>
                  Date
                </TableCell>
                <TableCell align="left" className={st.tableCellHead} style={{ color: fgColor, paddingRight: '25px' }}>
                  Geotag
                </TableCell>
                {periods.map((item, index) => (
                  <TableCell
                    key={`period-table-cell-${index}`}
                    align="center"
                    className={st.tableCellHead}
                    style={{ color: fgColor, paddingRight: '25px' }}
                  >
                    {item.period.join('—')}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {valueInitial.map((item, index) => (
                <TableRow
                  key={`value-table-row-${index}`}
                  style={{
                    height: `${BASE_HEIGHT}px`,
                    backgroundColor: getColorOfRow(index),
                  }}
                >
                  <TableCell scope="row" className={st.tableCell} style={{ color: fgColor }}>
                    {format(parseISO(item.date), 'dd-MM-yyyy')}
                  </TableCell>
                  <TableCell align="left" className={st.tableCell} style={{ color: fgColor }}>
                    {item.geotag}
                  </TableCell>
                  {periods.map((period, _index) => (
                    <TableCell
                      key={`period-table-cell-${_index}`}
                      align="center"
                      className={st.tableCell}
                      style={{ color: fgColor }}
                    >
                      {getCorrectCell(item.periods, period)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
      {periods.length === 0 && valueInitial.length === 0 && <EmptyTableWithoutColumns fgColor={fgColor} />}

      {periods.length > 0 && valueInitial.length === 0 && (
        <EmptyTableWithColumns
          fgColor={fgColor}
          infoText={'If you just configured tracking table you need to click "Get data" button'}
        />
      )}
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_TrackingTable;
