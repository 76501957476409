import { ExportToCsv } from 'export-to-csv';

const downloadCSVGeotagsTable = (object, device) => {
  const value = object.objectProperties.find((item) => item.key === 'valueValue')?.value || [];
  let prepareForExport = [];

  value.forEach((item) => {
    const localItem = {
      Geotag: item.zoneNameReference,
      'Start date': item.from,
      'End date': item.to,
      Duration: item.duration,
    };

    prepareForExport.push(localItem);
  });

  const csvExporter = new ExportToCsv({
    title: device.value ? device.title : object.name,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    useTextFile: false,
    filename: device.value ? device.title : object.name,
    useBom: true,
    useKeysAsHeaders: true,
  });

  csvExporter.generateCsv(prepareForExport.reverse());
};

export default downloadCSVGeotagsTable;
