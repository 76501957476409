import { useLazyQuery } from '@apollo/client';
import { GET_NOTIFICATIONS_DELIVERIES } from '@modules/notifications/api/getNotificationsDeliveries';
import { LISTEN_NOTIFICATIONS } from '@modules/notifications/api/listenNotification';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import { RootState } from '@src/store';
import { CHANNELS } from '@utils/notificationProfiles';
import { Fragment, lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Paper } from '@mui/material';
import st from './style.module.css';

const ModalContent = lazy(() => import('./components/ModalContent'));

const NotificationsModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isNewNotifications, setIsNewNotifications] = useState<boolean>(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const audio = new Audio('/notify.mp3');

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = () => {
    setIsNewNotifications(false);
    setOpen(true);
  };

  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useSelector((state: RootState) => state.user);
  const profile = useSelector((state: RootState) => state.profile);

  const [loadNotifications, { subscribeToMore, loading, data: notifications }] = useLazyQuery(
    GET_NOTIFICATIONS_DELIVERIES,
    {
      variables: {
        condition: {
          user: user?.id,
          deliveryPath: 'PixelBoard',
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    }
  );

  const getInAppProfile = (): boolean => {
    if (!user) {
      return false;
    }

    const targetProfile = user.userProfiles[0]?.profile.objectsToObjectsByObject1Id?.find(
      (item) => item.object2.deliveryChannel === CHANNELS.IN_APP
    );

    if (!targetProfile) {
      return false;
    }

    return Boolean(
      targetProfile?.object2?.objectProperties?.find((item) => item.key === 'settingsSoundInTheApp').value
    );
  };

  useEffect(() => {
    if (user && open) {
      void loadNotifications();
    }
  }, [user, open]);

  useEffect(() => {
    if (user) {
      if (!getInAppProfile()) {
        audio.pause();
        audio.currentTime = 0;
      }

      return subscribeToMore({
        document: LISTEN_NOTIFICATIONS,
        variables: {
          deliveryUser: user?.id,
          deliveryPath: 'PixelBoard',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateQuery: (prev, { subscriptionData }) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (subscriptionData.data.Notifications.relatedNode?.id) {
            setIsNewNotifications(true);

            if (getInAppProfile()) {
              void audio.play();
            }
          }
          return subscriptionData;
        },
      });
    }
  }, [user, profile]);

  return (
    <Fragment>
      {!isSm && (
        <Paper className={st.notificationIconContainer}>
          <IconButton
            size="small"
            className={st.notificationIcon}
            aria-label="notifications"
            ref={anchorRef}
            sx={{
              color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
            }}
            onClick={handleOpen}
          >
            <Badge {...(isNewNotifications ? { variant: 'dot' } : {})} classes={{ dot: st.dot }} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Paper>
      )}
      {isSm && (
        <IconButton aria-label="notifications" ref={anchorRef} onClick={handleOpen} size="small">
          <Badge {...(isNewNotifications ? { variant: 'dot' } : {})} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      )}
      {open && (
        <Suspense fallback={null}>
          <ModalContent
            open={open}
            anchorRef={anchorRef}
            isLoading={loading}
            notificationsQuery={notifications}
            closeModal={closeModal}
          />
        </Suspense>
      )}
    </Fragment>
  );
};

export default NotificationsModal;
