import { OptionValue } from '@components/common/ImageItem/components/MediaAutocomplete';
import * as yup from 'yup';

export interface IFormValues {
  property: OptionValue;
  value: string;
  alias?: string;
}

export const validationSchema = yup.object({
  property: yup.object().required('Property is required'),
  value: yup.string().trim().required('Value is required'),
  alias: yup.string().max(50, 'Custom name must be less than 50 characters'),
});
