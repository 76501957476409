import { ReactComponent as IconModal } from '@assets/icon_board_about.svg';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { memo } from 'react';

const IconWindow = () => (
  <Grid pl="16px" pb="8px" pt="24px">
    <Box display="flex" justifyContent="center" alignItems="center">
      <IconModal />
    </Box>
  </Grid>
);

export default memo(IconWindow);
