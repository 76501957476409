import { getBackgroundInput } from '@components/widgets/utils/getBackgroundInput';

export default function getColorOfRow(index, colors) {
  const isOdd = () => index % 2;

  if (isOdd()) {
    return '';
  }

  const theme = colors[0];
  return getBackgroundInput(theme);
}
