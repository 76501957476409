import RpcSubscribeWrapper from '@components/side-card/static-table/RpcSubscribeWrapper';
import { ColorSchemasEnum } from '@constants/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/system';
import useColors from '@utils/useColors';
import { ReactNode, useMemo } from 'react';

import st from './style.module.css';

interface IProps {
  onClick: () => void;
  colorSchema: ColorSchemasEnum;
  children: ReactNode;
  action: { value: string; objectId: string };
  disabled: boolean;
}

const RpcButton = ({ onClick, colorSchema, action, children, disabled }: IProps) => {
  const { getFieldStyleBasedOnColorSchema } = useColors();
  const theme = useTheme();
  const colorSchemaMap = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => getFieldStyleBasedOnColorSchema({ colorSchema, field: 'button' }),
    [colorSchema, theme.palette.mode]
  );

  return (
    <div
      style={{
        display: 'flex',
        padding: '5px 0',
        alignItems: 'center',
        justifyContent: 'stretch',
      }}
    >
      <RpcSubscribeWrapper
        isConfirm={true}
        rpcName={action.value}
        disabled={disabled}
        objectId={action.objectId}
        object={null}
        handler={onClick}
      >
        <LoadingButton
          className={st.button}
          sx={colorSchemaMap}
          variant={colorSchema !== ColorSchemasEnum.LIGHT_ON_TRANSPARENT ? 'outlined' : 'contained'}
          fullWidth
          disableElevation
        >
          {children}
        </LoadingButton>
      </RpcSubscribeWrapper>
    </div>
  );
};

export default RpcButton;
