import { getBackgroundInput } from '@components/widgets/utils/getBackgroundInput';
import { ArrowDropUp } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled, useTheme } from '@mui/system';
import { ConfigProvider, DatePicker, theme } from 'antd';
import { useState } from 'react';
import { RANGE_PRESETS } from '../utils/constants';

const { RangePicker } = DatePicker;

const DateRange = ({ settingsStyle, fgColor, onChange, date, isShowPresets }) => {
  const [isCalendarOpened, setCalendarOpen] = useState(false);

  const reactTheme = useTheme();
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const DateRange = styled(RangePicker)(({}) => ({
    [`&`]: {
      backgroundColor: getBackgroundInput(settingsStyle),
      borderColor: fgColor,
      padding: '4px 4px 4px 6px !important',
    },
    [`& .ant-picker-active-bar`]: {
      display: 'none; !important',
    },
    // [`& .ant-picker-range-arrow`]: {
    //   visibility: "hidden !important",
    // },
    [`&:hover`]: {
      backgroundColor: `${getBackgroundInput(settingsStyle)}`,
      borderColor: fgColor,
    },
    [`&:focus`]: {
      backgroundColor: `${getBackgroundInput(settingsStyle)}`,
      borderColor: fgColor,
    },
    '&.ant-picker-focused': {
      backgroundColor: `${getBackgroundInput(settingsStyle)}`,
      borderColor: fgColor,
      boxShadow: 'none',
    },
    '& .ant-picker-input input::-webkit-input-placeholder': {
      color: fgColor,
      opacity: 1,
    },
    '& .ant-picker-outlined:hover': {
      backgroundColor: `${getBackgroundInput(settingsStyle)} !important`,
    },
    '& .ant-picker-input input': {
      textAlign: 'center',
      fontSize: '13px',
      color: fgColor,
    },
    '& .ant-picker-input input:disabled': {
      color: 'rgba(0, 0, 0, 0.26);',
    },
    '&.ant-picker-disabled': {
      pointerEvents: 'none',
      borderColor: 'transparent',
      backgroundColor: 'rgba(255, 255, 255, 0.15); !important',
    },
    '& .ant-picker-range-separator': {
      padding: '0 1px',
    },
    '& .ant-picker-suffix': {
      marginInlineStart: '0',
    },
  }));

  return (
    <ConfigProvider
      theme={{
        algorithm: reactTheme.palette.mode === 'dark' ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: '#2780E3',
          activeBg: '2780E3',
          borderRadius: 5,
          inputFontSize: 13,
        },
      }}
    >
      <DateRange
        onOpenChange={(open) => {
          setCalendarOpen(open);
        }}
        open={isCalendarOpened}
        value={date}
        presets={isShowPresets ? RANGE_PRESETS : []}
        allowClear={false}
        format={{
          format: 'DD.MM.YYYY',
          type: 'mask',
        }}
        separator={<div style={{ color: fgColor }}>-</div>}
        suffixIcon={
          <div
            style={{
              color: fgColor,
              width: '22px',
              height: '22px',
            }}
          >
            {isCalendarOpened ? <ArrowDropUp /> : <ArrowDropDownIcon />}
          </div>
        }
        onChange={onChange}
      />
    </ConfigProvider>
  );
};

export default DateRange;
