import { useSubscription } from '@apollo/client';
import WidgetInitInfo from '@components/common/WidgetInitInfo';
import { ScaleOptionsEnum } from '@components/create-widget/regular-chart/constants';
import WidgetEditControls from '@components/WidgetEditControls';
import { ColorSchemasEnum, COLOR_SCHEMAS, highlightSelectedStyle } from '@constants/constants';
import { WidgetProps } from '@modules/reports/components/WidgetWrap';
import { CloudOffOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import { ObjectProperty } from '@src/__generated__/graphql';
import useColors from '@utils/useColors';
import { useInterval } from '@utils/useTimer';
import ReactEChartsCore from 'echarts-for-react';
import { LineChart } from 'echarts/charts';
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useState } from 'react';
import { TPoint } from './charts';
import ChartSubTitle from './ChartSubTitle';
import ChartSummary from './ChartSummary';
import ChartTitle from './ChartTitle';
import { axisLabelFormatter, CHART_STATUES, rndRange, simulateData } from './constants';
import { SUBSCRIBE_TO_OBJECT } from './SubscribeToObject';

type ChartProperty = {
  fragmentId: string;
  title: string;
  value: string;
};

const addPrefixDuplicate = (currentTitle: string, otherTitles: string[], prefix: string): string => {
  if (currentTitle && otherTitles?.includes(currentTitle)) {
    return `${currentTitle} ${prefix}`;
  }
  return currentTitle;
};

const DataChartWidget = (props: WidgetProps) => {
  const { id, objectProperties, selected } = props;
  const objectPropertiesMap: { [key: string]: ObjectProperty } = objectProperties?.reduce((acc, item) => {
    if (!acc[item.key]) {
      acc[item.key] = item;
    }
    return acc;
  }, {});

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    DataZoomComponent,
  ]);

  const theme = useTheme();
  const getPropValue = (prop: string): unknown => objectPropertiesMap[prop]?.value;
  const getProp = (prop: string): ObjectProperty => objectPropertiesMap[prop];
  const size = getPropValue('settingsSize') as string;
  const wide = getPropValue('settingsFullLine');
  const [chartInstance, setChartInstance] = useState<{
    clear: () => void;
    resize: () => void;
  } | null>(null);
  const [styleChart1Label, setStyleChart1Label] = useState<string>(getPropValue('styleChart1Label') as string);
  const [styleChart2Label, setStyleChart2Label] = useState<string>(getPropValue('styleChart2Label') as string);
  const [styleChart3Label, setStyleChart3Label] = useState<string>(getPropValue('styleChart3Label') as string);

  const [styleChartYaxisLabel, setStyleChartYaxisLabel] = useState(getPropValue('styleY-AxisLabel') as string);
  const [lineWidth, setLineWidth] = useState((getPropValue('settingsLineWidth') as string) || '2');
  const [isShowTitle, setIsShowTitle] = useState(getPropValue('settingsTitle') || false);
  const [isShowSubTitle, setIsShowSubTitle] = useState(getPropValue('settingsTitleSecondary') || false);

  let valueInitial: TPoint[] = [];
  let valueInitial2: TPoint[] = [];
  let valueInitial3: TPoint[] = [];

  const simulation = getPropValue('settingsSimulation');
  const settingsTimeInterval: string = getPropValue('settingsTimeInterval') as string;

  if (!simulation) {
    if (getPropValue('valueChart1')) {
      if (Array.isArray(getPropValue('valueChart1'))) {
        valueInitial = getPropValue('valueChart1') as TPoint[];
      }
    }

    if (getPropValue('valueChart2')) {
      if (Array.isArray(getPropValue('valueChart2'))) {
        valueInitial2 = getPropValue('valueChart2') as TPoint[];
      }
    }

    if (getPropValue('valueChart3')) {
      if (Array.isArray(getPropValue('valueChart3'))) {
        valueInitial3 = getPropValue('valueChart3') as TPoint[];
      }
    }
  }

  const fill = getPropValue('settingsFill') as boolean;
  const smoothType = getPropValue('settingsSmoothType');
  const type = getPropValue('settingsChartType') as string;
  const style = getPropValue('settingsStyle') as ColorSchemasEnum;
  const isAutoScale = getPropValue('settingsYaxisScale') === ScaleOptionsEnum.auto;
  const settingsMaximum = getPropValue('settingsMaximum');
  const settingsMinimum = getPropValue('settingsMinimum');
  const { getColorBasedOnStyle } = useColors();

  const [{ fg: fgColor, bg: bgColor }, setColorsBasedOnStyle] = useState(getColorBasedOnStyle(style));

  const mapColorByName = (color: string): string => {
    const colors = getColorBasedOnStyle(style);

    const colorsMap = {
      red: { color: theme.palette.wRed },
      green: { color: theme.palette.wGreen },
      orange: { color: theme.palette.wOrange },
      yellow: { color: theme.palette.wYellow },
      blue: { color: theme.palette.wBlue },
      black: { color: theme.palette.wBlack },
      white: { color: theme.palette.wWhite },
      default: { color: colors.fg },
      gray3: { color: theme.palette.gray3 },
    };

    if (color === null || color.includes('#')) {
      return colorsMap.default.color;
    }

    return colorsMap[color].color as string;
  };

  const [styleChart1Color, setStyleChart1Color] = useState(mapColorByName(getPropValue('styleChart1Color') as string));

  const [styleChart2Color, setStyleChart2Color] = useState(mapColorByName(getPropValue('styleChart2Color') as string));

  const [styleChart3Color, setStyleChart3Color] = useState(mapColorByName(getPropValue('styleChart3Color') as string));

  const [settingsShowTable, setSettingsShowTable] = useState(getPropValue('settingsShowTable'));

  const [settingsTableColumns, setSettingsTableColumns] = useState(getPropValue('settingsTableColumns'));
  const [settingsLevel, setSettingsLevel] = useState(
    getPropValue('settingsLevels') as {
      value: number;
      label: string;
      color: string;
    }[]
  );

  const timerSimulation = useInterval();

  const [value, setValue] = useState(valueInitial);
  const [value2, setValue2] = useState(valueInitial2);
  const [value3, setValue3] = useState(valueInitial3);

  const [chartStatus, setChartStatus] = useState('');

  const setSimulatedData = () => {
    const { simulatedData, simulatedData2, simulatedData3 } = simulateData();

    setValue(simulatedData);
    setValue2(simulatedData2);
    setValue3(simulatedData3);
  };

  const setData = () => {
    setValue((prevState) => [
      ...prevState.filter((item, index) => index !== 0),
      {
        x: prevState.length ? prevState[prevState.length - 1].x + 3600 * 1000 : 3600 * 1000,
        y: rndRange(20, 30),
      },
    ]);

    setValue2((prevState) => [
      ...prevState.filter((item, index) => index !== 0),
      {
        x: prevState.length ? prevState[prevState.length - 1].x + 3600 * 1000 : 3600 * 1000,
        y: rndRange(50, 90),
      },
    ]);

    setValue3((prevState) => [
      ...prevState.filter((item, index) => index !== 0),
      {
        x: prevState.length ? prevState[prevState.length - 1].x + 3600 * 1000 : 3600 * 1000,
        y: rndRange(10, 90),
      },
    ]);
  };

  const colors = [getPropValue('settingsStyle'), null];

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return '';
    }

    const themeLocal = colors[0];

    switch (themeLocal) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return '#F1F1F1';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  };

  useEffect(() => {
    if (simulation) {
      if (timerSimulation.current) {
        clearInterval(timerSimulation.current);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timerSimulation.current = setInterval(setData, 1000);
      setSimulatedData();
    } else {
      if (chartInstance?.clear) {
        chartInstance?.clear();
        chartInstance?.resize();
      }
      setValue(valueInitial);
      setValue2(valueInitial2);
      setValue3(valueInitial3);
      if (timerSimulation.current) {
        clearInterval(timerSimulation.current);
      }
    }
  }, [simulation, type]);

  useEffect(() => {
    setColorsBasedOnStyle(getColorBasedOnStyle(style));
    setStyleChart1Color(mapColorByName(getPropValue('styleChart1Color') as string));
    setStyleChart2Color(mapColorByName(getPropValue('styleChart2Color') as string));
    setStyleChart3Color(mapColorByName(getPropValue('styleChart3Color') as string));
  }, [style, theme.palette.mode]);

  const resizeChart = () => {
    if (chartInstance) {
      setTimeout(() => {
        chartInstance.resize();
      }, 100);
    }
  };

  const checkIsConfigured = () => {
    const values = [getPropValue('valueChart1'), getPropValue('valueChart2'), getPropValue('valueChart3')];
    const configured = [getPropValue('chart1Property'), getPropValue('chart2Property'), getPropValue('chart3Property')];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!configured.some((item) => item.value) && values.every((item) => !item.length)) {
      setChartStatus(CHART_STATUES.NO_CONFIGURED);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (configured.some((item) => item.value) && values.every((item) => !item.length)) {
      setChartStatus(CHART_STATUES.CONFIGURED_NO_DATA);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (configured.some((item) => item.value) && values.some((item) => item.length)) {
      setChartStatus(CHART_STATUES.CONFIGURED);
    }

    resizeChart();
  };

  const subscribeHandler = {
    default: () => {},
    valueChart1: (incomingValue: unknown) => {
      if (Array.isArray(incomingValue) && !simulation) {
        const valueChart: TPoint[] = incomingValue || [];
        setValue(
          valueChart.map((v: TPoint): TPoint => {
            return { ...v, id: `${v.x}_${v.y}` };
          })
        );
      }
    },
    valueChart2: (incomingValue: unknown) => {
      if (Array.isArray(incomingValue) && !simulation) {
        const valueChart: TPoint[] = incomingValue || [];

        setValue2(
          valueChart.map((v) => {
            return { x: v.x, y: v.y, id: `${v.x}_${v.y}` };
          })
        );
      }
    },
    valueChart3: (incomingValue: unknown) => {
      if (Array.isArray(incomingValue) && !simulation) {
        const valueChart: TPoint[] = incomingValue || [];

        setValue3(
          valueChart.map((v) => {
            return { x: v.x, y: v.y, id: `${v.x}_${v.y}` };
          })
        );
      }
    },
    styleChart1Color: (incomingValue: string) => {
      setStyleChart1Color(mapColorByName(incomingValue));
    },
    styleChart2Color: (incomingValue: string) => {
      setStyleChart2Color(mapColorByName(incomingValue));
    },
    styleChart3Color: (incomingValue: string) => {
      setStyleChart3Color(mapColorByName(incomingValue));
    },
    styleChart1Label: (incomingValue: string) => {
      setStyleChart1Label(incomingValue);
    },
    styleChart2Label: (incomingValue: string) => {
      setStyleChart2Label(incomingValue);
    },
    styleChart3Label: (incomingValue: string) => {
      setStyleChart3Label(incomingValue);
    },
    'styleY-AxisLabel': (incomingValue: string) => {
      setStyleChartYaxisLabel(incomingValue);
    },
    settingsTableColumns: (incomingValue) => {
      setSettingsTableColumns(incomingValue);
    },
    settingsLineWidth: (incomingValue: string) => {
      setLineWidth(incomingValue);
    },
    settingsShowTable: (incomingValue) => {
      setSettingsShowTable(incomingValue);
      resizeChart();
    },
    settingsTitleSecondary: (incomingValue: boolean) => {
      setIsShowSubTitle(incomingValue);
      resizeChart();
    },
    settingsTitle: (incomingValue: boolean) => {
      setIsShowTitle(incomingValue);
      resizeChart();
    },
    settingsLevels: (incomingValue: { label: string; color: string; value: number }[]) => {
      setSettingsLevel(incomingValue);
    },
  };

  useSubscription(SUBSCRIBE_TO_OBJECT, {
    variables: { objId: [id] },
    onData: ({
      data: {
        data: {
          Objects: { relatedNode },
        },
      },
    }) => {
      if (relatedNode?.__typename === 'ObjectProperty') {
        const fn: (value: unknown) => void = subscribeHandler[relatedNode?.key];
        const fnDefault: () => void = subscribeHandler.default;

        if (!fn) {
          fnDefault();
        } else {
          fn(relatedNode?.value);
        }
        checkIsConfigured();
      }
    },
  });

  useEffect(() => {
    checkIsConfigured();
  }, [id, chartInstance]);

  let areaStyleOpacity = 0;

  if (fill) areaStyleOpacity = 0.7;

  let step, smooth;

  switch (smoothType) {
    case 'linear':
      step = '';
      smooth = false;
      break;

    case 'stepwise':
      step = 'start';
      smooth = true;
      break;

    case 'smooth':
      step = '';
      smooth = true;
      break;
  }

  const configured = [
    getPropValue('chart1Property') as { value: string },
    getPropValue('chart2Property') as { value: string },
    getPropValue('chart3Property') as { value: string },
  ];

  const howManyPropertiesFilled = configured.map((item) => item.value).filter((item) => item);

  const getSubtitle = () => {
    interface ISpec {
      spec: {
        valueSet: {
          list: { title: string; key: string }[];
        };
      };
    }

    const prop = getProp('settingsTimeInterval') as unknown as ISpec;
    const groupBy = getProp('chart1GroupBy') as unknown as ISpec;
    const groupingFunction = getProp('chart1GroupingFunction') as unknown as ISpec;

    const timeInterval =
      prop.spec.valueSet.list.find((item) => item.key === (getPropValue('settingsTimeInterval') as string))?.title ||
      '';

    const groupByString =
      groupBy.spec.valueSet.list.find((item) => item.key === (getPropValue('chart1GroupBy') as string))?.title || '';

    const groupingFunctionString =
      groupingFunction.spec.valueSet.list.find(
        (item) => item.key === (getPropValue('chart1GroupingFunction') as string)
      )?.title || '';

    let string = '';

    string += `Interval: ${timeInterval}`;

    if (howManyPropertiesFilled.length === 1) {
      if (groupByString) {
        string += ` | Group by: ${groupByString}`;
      }

      if (groupingFunctionString) {
        string += ` | Function: ${groupingFunctionString}`;
      }
    }

    return string;
  };

  const chart1Property = getPropValue('chart1Property') as {
    value: string;
    title: string;
  };
  const chart2Property = getPropValue('chart2Property') as {
    value: string;
    title: string;
  };
  const chart3Property = getPropValue('chart3Property') as {
    value: string;
    title: string;
  };
  const series = [];

  if (chart1Property.value || simulation) {
    series.push({
      id: '1',
      name: addPrefixDuplicate(
        styleChart1Label || chart1Property.title,
        [styleChart2Label || chart2Property.title, styleChart3Label || chart3Property.title],
        '(Chart 1)'
      ),
      data: value,
      color: styleChart1Color,
      label:
        styleChart1Label && addPrefixDuplicate(styleChart1Label, [styleChart2Label, styleChart3Label], '(Chart 1)'),
    });
  }

  if (chart2Property.value || simulation) {
    series.push({
      id: '2',
      name: addPrefixDuplicate(
        styleChart2Label || chart2Property.title,
        [styleChart1Label || chart1Property.title, styleChart3Label || chart3Property.title],
        '(Chart 2)'
      ),
      data: value2,
      color: styleChart2Color,
      label:
        styleChart2Label && addPrefixDuplicate(styleChart2Label, [styleChart1Label, styleChart3Label], '(Chart 2)'),
    });
  }

  if (chart3Property.value || simulation) {
    series.push({
      id: '3',
      name: addPrefixDuplicate(
        styleChart3Label || chart3Property.title,
        [styleChart2Label || chart2Property.title, styleChart1Label || chart1Property.title],
        '(Chart 3)'
      ),
      data: value3,
      color: styleChart3Color,
      label:
        styleChart3Label && addPrefixDuplicate(styleChart3Label, [styleChart2Label, styleChart1Label], '(Chart 3)'),
    });
  }

  const chartOptions = {
    animation: false,
    color: fgColor,
    grid: {
      top: 40,
      right: 15,
      bottom: 8,
      left: 8,
      containLabel: true,
    },
    xAxis: {
      animationThreshold: 500,
      show: size !== 'small',
      type: 'time',
      axisTick: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
      splitLine: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
      },
      axisLabel: {
        hideOverlap: true,
        formatter(valueAxis: number) {
          return axisLabelFormatter(valueAxis, settingsTimeInterval);
        },
      },
      axisLine: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
    },
    barMaxWidth: 40,
    barMinWidth: 10,
    yAxis: {
      min: isAutoScale ? undefined : settingsMinimum,
      max: isAutoScale ? undefined : settingsMaximum,
      animationThreshold: 500,
      nameLocation: 'center',
      axisLabel: {
        formatter: `{value} ${styleChartYaxisLabel || ''}`,
      },
      type: 'value',
      axisTick: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
      splitLine: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
      scale: isAutoScale,
      axisLine: {
        lineStyle: { color: fgColor, opacity: 0.3 },
        show: true,
      },
    },
    dataZoom: [
      {
        type: 'inside',
        zoomOnMouseWheel: 'ctrl',
      },
    ],
    textStyle: {
      color: fgColor,
    },
    legend: {
      type: 'scroll',
      selectedMode: howManyPropertiesFilled.length === 1 && !simulation ? false : 'multiply',
      itemWidth: 20,
      itemHeight: 14,
      formatter(v: string) {
        return v.slice(0, 20);
      },
      icon: 'circle',
      textStyle: {
        fontSize: '14',
        fontWeight: 'bold',
        color: fgColor,
      },
      orient: 'horizontal',
      top: 8,
      itemGap: 20,
      right: 15,
    },
    series: series.map((item) => ({
      data: item.data.map((point) => {
        if (point.x || point.y) {
          return {
            id: `${point.x}_${point.y}`,
            value: [point.x, point.y],
          };
        } else {
          return {};
        }
      }),
      symbolSize: 6,
      markLine: {
        symbol: ['none', 'none'],
        label: {
          position: 'middle',
          show: false,
        },
        emphasis: {
          label: {
            backgroundColor: 'white',
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowOffsetX: 3,
            shadowOffsetY: 3,
            fontSize: '12px',
            padding: [5, 10],
            borderRadius: 4,
            show: true,
            formatter: (params) => {
              return `${params.name}: ${params.value}`;
            },
          },
        },
        data: settingsLevel.map((level) => ({
          name: level.label,
          yAxis: level.value,
          lineStyle: {
            color: mapColorByName(level.color),
            width: 3,
            type: 'dotted',
          },
        })),
        silent: false,
      },
      type,
      lineStyle: {
        width: Number(lineWidth),
        color: item.color,
      },
      smooth,
      step,
      name: item.label || item.name,
      areaStyle: {
        opacity: areaStyleOpacity,
        color: item.color,
      },
      emphasis: {
        lineStyle: {
          color: item.color,
        },
        areaStyle: {
          color: item.color,
          opacity: areaStyleOpacity,
        },
      },
      itemStyle: { color: item.color },
    })),
    tooltip: {
      triggerOn: 'click',
      trigger: 'axis',
      confine: true,
    },
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexBasis: wide ? '100%' : 'auto',
          flexGrow: 1,
          position: 'relative',
          height: `100%`,
          backgroundColor: bgColor,
          overflow: 'hidden',
          filter: selected ? highlightSelectedStyle : '',
          borderRadius: '2px',
        }}
      >
        {isShowTitle && <ChartTitle fgColor={fgColor}>{props.name}</ChartTitle>}
        {(value.length > 0 || value2.length > 0 || value3.length > 0) && (
          <>
            {isShowSubTitle && <ChartSubTitle fgColor={fgColor}>{getSubtitle()}</ChartSubTitle>}

            {chartOptions && (
              <ReactEChartsCore
                echarts={echarts}
                option={chartOptions}
                onChartReady={setChartInstance}
                lazyUpdate={true}
                style={{ width: '100%', height: '100%' }}
              />
            )}

            {settingsShowTable && (
              <ChartSummary
                settingsTableColumns={settingsTableColumns}
                fgColor={fgColor}
                bgColor={bgColor}
                value3={value3}
                value2={value2}
                value={value}
                getColorOfRow={getColorOfRow}
                styleChart1Label={styleChart1Label || (getPropValue('chart1Property') as ChartProperty)?.title}
                styleChart2Label={styleChart2Label || (getPropValue('chart2Property') as ChartProperty)?.title}
                styleChart3Label={styleChart3Label || (getPropValue('chart3Property') as ChartProperty)?.title}
              />
            )}
          </>
        )}
        {chartStatus === CHART_STATUES.NO_CONFIGURED && !simulation && (
          <WidgetInitInfo fgColor={fgColor} infoText={'Make sure you configured at least one chart'} icon={undefined} />
        )}
        {chartStatus === CHART_STATUES.CONFIGURED_NO_DATA && !simulation && (
          <WidgetInitInfo
            icon={<CloudOffOutlined fontSize={'large'} />}
            fgColor={fgColor}
            mainText={'No data for selected period'}
            infoText={undefined}
          />
        )}
        <WidgetEditControls {...props} />
      </div>
    </>
  );
};

export default DataChartWidget;
