import { gql, useMutation } from '@apollo/client';
import ClockOutline from '@components/icons/clockOutline';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ParameterDateRange from '@components/side-card/basic/ParameterDateRange';
import RpcSubscribeWrapper from '@components/side-card/basic/RpcSubscribeWrapper';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import HumanReadableProperty from '@components/side-card/geo-timer/HumanReadableProperty';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import ExtensionIcon from '@mui/icons-material/Extension';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { Suspense, useEffect, useState } from 'react';

import tabStyle from '@components/side-card/tab.module.css';
import { TimerOutlined } from '@mui/icons-material';
import MinimumDurationModal from './modals/MinimumDurationModal';
import MotionTimeoutModal from './modals/MotionTimeoutModal';
import ParameterObjectModal from './modals/ParameterObjectModal';
import SelectType from './modals/SelectType';

const UPDATE_PROPERTIES_BY_IDS = gql`
  mutation updateObjectPropertiesById($input: UpdateObjectPropertiesByIdInput!) {
    updateObjectPropertiesById(input: $input) {
      clientMutationId
    }
  }
`;

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      clientMutationId
      controlExecution {
        id
      }
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GeotagsTableGeneral = (props) => {
  const { item } = props;
  const [createExecution] = useMutation(CREATE_EXECUTION);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const getProperty = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const settingsDateRange = () => {
    return item.objectProperties.find((item) => item.key === 'settingsDate');
  };

  const settingsMinimumDuration = () => {
    return item.objectProperties.find((item) => item.key === 'settingsMinimumDuration');
  };

  const settingsNoMotionTimeout = () => {
    return item.objectProperties.find((item) => item.key === 'settingsNoMotionTimeout') || { value: 0 };
  };

  const [isMayGetData, setIsMayGetData] = useState(false);

  useEffect(() => {
    if (!settingsDateRange().value.value || !getProperty('settingsObject').value?.value) {
      setIsMayGetData(false);
    } else {
      setIsMayGetData(true);
    }
  }, [item]);

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'UpdateReport',
            objectId: item.id,
            params: {
              object: getProperty('settingsObject').value.value,
              range: getProperty('settingsDate').value.value,
              minimum_duration: getProperty('settingsMinimumDuration').value,
              no_motion_timeout: getProperty('settingsNoMotionTimeout').value || 0,
            },
          },
        },
      },
    });
  };

  const settingsType = () => {
    const schemaId = getProperty('settingsType')?.value;

    return {
      query: gql`
        query getSchema($id: UUID!) {
          schema(id: $id) {
            id
            name
          }
        }
      `,
      config: {
        variables: {
          id: schemaId,
        },
      },
    };
  };

  return (
    <>
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Parameters</Typography>
        </ListSubheader>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }}>
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                <span>Type: </span>
                {getProperty('settingsType').value && (
                  <>
                    <Suspense fallback={'Loading...'}>
                      <HumanReadableProperty
                        payload={settingsType('settingsType')}
                        getValue={(data) => data?.schema?.name || 'n/a'}
                      ></HumanReadableProperty>
                    </Suspense>
                  </>
                )}
                {!getProperty('settingsType')?.value && 'n/a'}
              </Typography>
            }
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                SelectType({
                  handleSave: (id, value) => {
                    return updateProperties({
                      variables: {
                        input: {
                          propertiesArray: [
                            {
                              propertyId: getProperty('settingsType').id,
                              value: value,
                            },
                            {
                              propertyId: getProperty('settingsObject').id,
                              value: { title: 'n/a', value: null },
                            },
                            {
                              propertyId: getProperty('valueValue').id,
                              value: [],
                            },
                          ],
                        },
                      },
                    });
                  },
                  id: getProperty('settingsType').id,
                  value: getProperty('settingsType').value,
                })
                  .then()
                  .catch();
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }}>
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <ListItemText title={getProperty('settingsObject')?.value?.title || 'n/a'}>
            <Typography noWrap variant="body1">
              Object: {getProperty('settingsObject')?.value?.title || 'n/a'}
            </Typography>
          </ListItemText>
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getProperty('settingsObject');

                ParameterObjectModal({
                  type: getProperty('settingsType').value,
                  value: target?.value?.value,
                  save: (object) => {
                    updateProperties({
                      variables: {
                        input: {
                          propertiesArray: [
                            {
                              propertyId: target.id,
                              value: object,
                            },
                            {
                              propertyId: getProperty('valueValue').id,
                              value: [],
                            },
                          ],
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
        >
          <ListItemIcon>
            <EventIcon></EventIcon>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Date: {settingsDateRange().value.title}</Typography>} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              onClick={() => {
                ParameterDateRange({
                  selected: settingsDateRange(),
                  save: (date) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsDateRange().id,
                          patch: {
                            value: date,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
        >
          <ListItemIcon>
            <ClockOutline></ClockOutline>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Minimum duration: {settingsMinimumDuration().value} sec</Typography>}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              onClick={() => {
                MinimumDurationModal({
                  value: settingsMinimumDuration()?.value,
                  handleSave: (value) =>
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsMinimumDuration().id,
                          patch: {
                            value,
                          },
                        },
                      },
                    }),
                }).then(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
        >
          <ListItemIcon>
            <TimerOutlined></TimerOutlined>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">No motion timeout: {settingsNoMotionTimeout().value} sec</Typography>}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              onClick={() => {
                MotionTimeoutModal({
                  value: settingsNoMotionTimeout()?.value,
                  handleSave: (value) =>
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsNoMotionTimeout().id,
                          patch: {
                            value,
                          },
                        },
                      },
                    }),
                }).then(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={'UpdateReport'}
            objectId={item.id}
            disabled={!isMayGetData}
            object={item}
            title={'Update table'}
            handler={rpcHandler}
          >
            <LoadingButton fullWidth={true} variant={'outlined'}></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default GeotagsTableGeneral;
