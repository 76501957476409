import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

export interface IListItem {
  name?: string;
  value?: string;
  type?: 'text' | 'link' | 'title';
}

const ListItem: FC<IListItem> = ({ name, value, type }) => {
  const theme = useTheme();
  return (
    <Grid paddingBlock={type === 'title' ? '13px' : '12px'} pl="16px">
      <Box display="flex" justifyContent="space-between">
        {type === 'title' ? (
          <Typography color="primary" variant="subtitle2">
            {name}
          </Typography>
        ) : (
          <span>{name}</span>
        )}
        {type === 'text' ? (
          <span>{value}</span>
        ) : (
          <Link
            target="_blank"
            to={value}
            style={{
              textDecorationColor: theme.palette.primary.main,
            }}
          >
            <Typography color="primary" variant="body1">
              {value}
            </Typography>
          </Link>
        )}
      </Box>
    </Grid>
  );
};

export default memo(ListItem);
