import { useQuery } from '@apollo/client';
import { FORMAT_OPTIONS, SETTINGS_SIZE_OPTIONS } from '@components/create-widget/databox/constants';
import { useAddWidget } from '@components/create-widget/useAddWidget';
import useCustomNavigate from '@components/hooks/useCustomNavigate';
import useRoute from '@hooks/useRoute';
import { GET_WIDGET } from '@modules/widgets/api/GetWidget';
import { IAdvancedButtonWidgetModalProps } from '@modules/widgets/forms/AdvancedButtonWidgetModal';
import { SETTINGS_STYLE_OPTIONS } from '@utils/constants/selectOptions';
import { WIDGETS_ENUM } from '@utils/widgetTypes';
import { IFormValues, validationSchema } from '../constants/formik';

const useForm = ({ onResolve, widget, isEdit, group, name: nameProps }: IAdvancedButtonWidgetModalProps) => {
  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group,
    widgetType: WIDGETS_ENUM.ADVANCED_BUTTON,
    cb: () => {},
  });

  const { data, loading: loadingWidgets } = useQuery(GET_WIDGET, {
    variables: { objId: widget?.id },
  });
  const getPropValue = (prop) => data?.object?.objectProperties?.find((obj) => obj.key === prop)?.value;
  const navigate = useCustomNavigate();
  const { entityId } = useRoute();

  const formik = {
    initialValues: {
      widgetId: widget?.id,
      name: isEdit ? data?.object?.name : '',
      format: isEdit ? getPropValue('settingsFormat') : FORMAT_OPTIONS[0].value,
      size: isEdit ? getPropValue('settingsSize') : SETTINGS_SIZE_OPTIONS[0].value,
      style: isEdit ? getPropValue('settingsStyle') : SETTINGS_STYLE_OPTIONS[0].value,
      description: isEdit ? data?.object?.description : '',
      widgetType: isEdit ? data?.object?.schemaName : '',
      text: isEdit ? getPropValue('valueText') : '',
    },
    validationSchema,
    onSubmit: ({ format, size, style, name, description, text }: IFormValues) => {
      {
        if (isEdit) {
          void updateWidget({
            values: {
              settingsStyle: style,
              settingsFormat: format,
              settingsSize: size,
              valueText: text,
            },
            defaultValues: {
              settingsSize: size,
            },
            id: widget?.id,
            name,
            description,
            cb: () => {
              onResolve();
            },
          });
        } else {
          void createWidgetFn({
            values: {
              settingsStyle: style,
              settingsFormat: format,
              settingsSize: size,
              valueText: text,
            },
            name: nameProps,
            description,
            cb: (e) => {
              const id = e?.data?.createObjectWithProperties?.uuid as string;
              if (id) navigate(`/boards/${entityId()}/${group?.id}/${id}`);
              onResolve();
            },
          });
        }
      }
    },
  };

  return { formik: isEdit ? data && formik : formik, isLoading: isLoading || loadingWidgets };
};

export default useForm;
