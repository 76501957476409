import * as yup from 'yup';

export interface IFormValues {
  name: string;
  widgetId: string;
}

export const validationSchema = yup.object({
  name: yup.string().trim(),
});
