import { useLazyQuery } from '@apollo/client';
import { ReactComponent as ImgAdmin } from '@assets/icon_admin.svg';
import { ReactComponent as PixelBilling } from '@assets/icon_billing.svg';
import { ReactComponent as ImgBoard } from '@assets/icon_board.svg';
import { ReactComponent as ImgMonitor } from '@assets/icon_monitor.svg';
import { ReactComponent as ImgSite } from '@assets/icon_site.svg';
import AppsIcon from '@mui/icons-material/Apps';
import { ButtonBase, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { gql } from '@src/__generated__';
import jwt_decode from 'jwt-decode';
import { Fragment, useRef, useState } from 'react';

import st from './style.module.css';

const APPS_QUERY = gql(/*GraphQL*/ `
    query userApps($id: UUID!) {
        user(id: $id) {
            userProfiles(filter: { object: { id: { isNull: false }  } }) {
                object {
                    id
                    enabled
                    name: property(propertyName:"Program/Name")
                    icon: property(propertyName:"Program/Icon")
                    url: property(propertyName:"Program/URL")
                }
            }
        }
    }
`);

const AppsModal = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const tokenDecoded: {
    user_id?: string;
  } = localStorage.getItem('authToken') ? jwt_decode(localStorage.getItem('authToken')) : null;

  const [load, { data, loading }] = useLazyQuery(APPS_QUERY, {
    variables: {
      id: tokenDecoded?.user_id,
    },
    fetchPolicy: 'network-only',
  });

  const handleClose = ({ target }: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(target as Node)) {
      return;
    }

    setOpen(false);
  };

  const handleOpen = () => {
    if (!open) {
      load()
        .then(() => {
          setOpen(true);
        })
        .catch(() => {});
    }
  };

  const handleClick = (url) => {
    setOpen(false);
    window.open(
      `${url}/from-app?token=${localStorage.getItem('refreshToken')}&tokenId=${localStorage.getItem('tokenId')}`,
      '_blank'
    );
  };

  const icons = {
    PixelSite: <ImgSite />,
    PixelAdmin: <ImgAdmin />,
    PixelBoard: <ImgBoard />,
    PixelMonitor: <ImgMonitor />,
    PixelBilling: <PixelBilling />,
  };

  return (
    <Fragment>
      <Paper className={st.buttonContainer}>
        <IconButton
          data-test-app-switcher
          ref={anchorRef}
          onClick={handleOpen}
          size="small"
          className={st.button}
          aria-label="apps"
          sx={{
            color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
          }}
        >
          {loading && <CircularProgress size={25} />}
          {!loading && <AppsIcon />}
        </IconButton>
      </Paper>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
        placement="bottom-end"
        disablePortal={true}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={8}>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={st.modalOverlay}>
                  {data?.user.userProfiles.map(({ object }) => {
                    return (
                      <ButtonBase
                        data-test-app-switch={object.name}
                        key={object.id}
                        sx={{
                          opacity: object.enabled ? '1' : '0.5',
                        }}
                        disabled={!object.enabled}
                        focusRipple
                        onClick={() => handleClick(object.url)}
                      >
                        <span>
                          {icons[object.name]}
                          <Typography variant="body1">{object.name}</Typography>
                        </span>
                      </ButtonBase>
                    );
                  })}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default AppsModal;
