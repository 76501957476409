import useMoreMenu from '@components/useMoreMenu';
import useRoute from '@hooks/useRoute';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

import st from './style.module.css';

const CardToolbarNormal = (props) => {
  const theme = useTheme();
  const { MoreMenu, openMoreMenu } = useMoreMenu();
  const { handleGoBack, handleGoStart, getTypeByRoute } = useRoute();

  const type = getTypeByRoute();

  return (
    <>
      <div className={st.root}>
        {type === 'group' && (
          <IconButton
            style={{
              background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
            }}
            className={st.btn}
            disabled
            size="medium"
          >
            <StarOutlineIcon />
          </IconButton>
        )}
        {type !== 'group' && (
          <IconButton
            data-test-card="back"
            style={{
              background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
            }}
            className={st.btn}
            onClick={handleGoBack}
            size="medium"
          >
            <ArrowBackIcon />
          </IconButton>
        )}

        <MoreMenu items={props.menuItems} handleMenuItemClick={props.handleMenuItemClick} />
        <div>
          <IconButton
            data-test-card="contextMenu"
            style={{
              marginRight: '10px',
              background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
            }}
            className={st.btn}
            onClick={(e) => openMoreMenu(e)}
            size="medium"
          >
            <MoreVertIcon />
          </IconButton>
          <IconButton
            data-test-card="close"
            style={{
              background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
            }}
            className={st.btn}
            onClick={handleGoStart}
            size="medium"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

const CardToolbarFixed = (props) => {
  const theme = useTheme();

  const { MoreMenu, openMoreMenu } = useMoreMenu();
  const { handleGoBack, handleGoStart, getTypeByRoute } = useRoute();

  const type = getTypeByRoute();

  return (
    <>
      <div
        style={{
          backgroundColor: theme.palette.background.default,
          zIndex: theme.zIndex.drawer - 1,
        }}
        className={st.rootFixed}
      >
        {type === 'group' && (
          <IconButton
            style={{
              background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
            }}
            className={st.btn}
            disabled
            size="medium"
          >
            <StarOutlineIcon />
          </IconButton>
        )}
        {type !== 'group' && (
          <IconButton
            style={{
              background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
            }}
            className={st.btn}
            onClick={handleGoBack}
            size="medium"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography noWrap={true} style={{ marginLeft: '12px' }} variant="h6">
          {props.item.name}
        </Typography>
        <span style={{ flexGrow: 1 }}></span>
        <MoreMenu items={props.menuItems} handleMenuItemClick={props.handleMenuItemClick} />
        <div style={{ flexShrink: 0 }}>
          <IconButton
            style={{
              background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
              marginRight: '10px',
            }}
            className={st.btn}
            onClick={(e) => {
              openMoreMenu(e);
            }}
            data-test-card="contextMenu"
            size="medium"
          >
            <MoreVertIcon />
          </IconButton>
          <IconButton
            style={{
              background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
            }}
            className={st.btn}
            onClick={handleGoStart}
            size="medium"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

const CardToolbar = (props) => {
  return (
    <>
      {props.isFixed && <CardToolbarFixed {...props}></CardToolbarFixed>}
      {!props.isFixed && <CardToolbarNormal {...props}></CardToolbarNormal>}
    </>
  );
};

export default CardToolbar;
