import { useQuery } from '@apollo/client';
import { FormatCommandButtonEnum } from '@components/create-widget/databox/DataboxTypes';
import ControlListItem from '@components/side-card/command-button/components/ControlListItem';
import ObjectListItem from '@components/side-card/command-button/components/ObjectListItem';
import StatusListItem from '@components/side-card/command-button/components/StatusListItem';
import StatusListItemNull from '@components/side-card/command-button/components/StatusListItemNull';
import { GET_OBJECT } from '@modules/objects/api/getControls';
import EditControlModal from '@modules/widgets/forms/CommandButton/EditControlModal';
import EditObjectModal from '@modules/widgets/forms/CommandButton/EditObjectModal';
import EditSwitchControlModal from '@modules/widgets/forms/CommandButton/EditSwitchControlModal';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { setSettings } from '@store/settingsSlice';
import { useDispatch } from 'react-redux';
import useMoreMenu from '../../useMoreMenu';
import AccessSection from '../basic/AccessSection';
import DescriptionSection from '../basic/DescriptionSection';
import ServiceSection from '../basic/ServiceSection';

import tabStyle from '@components/side-card/tab.module.css';

const CommandButtonGeneralTab = (props) => {
  const { item, propKey } = props;
  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };
  const buttonText = () => getPropertyByKey('valueText');
  const buttonOnText = () => getPropertyByKey('valueSwitchtexton');
  const objectId = () => getPropertyByKey(propKey)?.value?.objectId;
  const settingsFormat = getPropertyByKey('settingsFormat')?.value;
  const valueStatus = getPropertyByKey('valueStatus')?.value;
  const targetControlOffRPC = getPropertyByKey('targetControlOff')?.value?.rpc;
  const targetControlOnRPC = getPropertyByKey('targetControlOn')?.value?.rpc;
  const valueActionRPC = getPropertyByKey('valueAction')?.value?.rpc;

  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();
  const { MoreMenu: TextMenu, openMoreMenu: openTextMenu } = useMoreMenu();
  const { MoreMenu: TextOnMenu, openMoreMenu: openTextOnMenu } = useMoreMenu();
  const { MoreMenu: TextOffMenu, openMoreMenu: openTextOffMenu } = useMoreMenu();
  const dispatch = useDispatch();

  const { data: dataObject, loading } = useQuery(GET_OBJECT, {
    variables: {
      objectId: objectId(),
    },
  });
  const isHideParameter =
    settingsFormat === FormatCommandButtonEnum.button_text || settingsFormat === FormatCommandButtonEnum.button;
  const rpc =
    settingsFormat === FormatCommandButtonEnum.switcher || settingsFormat === FormatCommandButtonEnum.switcher_text
      ? valueStatus
        ? targetControlOnRPC
        : targetControlOffRPC
      : valueActionRPC;

  return (
    <>
      <List>
        <ColumnMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit',
              id: 'edit_prop',
              handleAction: (obj) => {
                EditObjectModal({
                  widgetId: obj.id,
                  propKey: propKey,
                })
                  .then()
                  .catch(() => {});
              },
            },
            {
              icon: <HistoryIcon />,
              title: 'Show history',
              id: 'show_history',
              disabled: true,
              handleAction: (obj) => {
                dispatch(setSettings({ isShowHistory: true, historyPropertyId: obj.propId }));
              },
            },
          ]}
        />
        <TextMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit action',
              id: 'edit_prop',
              handleAction: (obj) => {
                EditControlModal({
                  widgetId: obj.id,
                  propId: buttonText().id,
                  propKey: propKey,
                  isHideParameter: isHideParameter,
                })
                  .then()
                  .catch(() => {});
              },
            },
            {
              icon: <HistoryIcon />,
              title: 'Show history',
              id: 'show_history',
              handleAction: () => {
                dispatch(setSettings({ isShowHistory: true, historyPropertyId: buttonText().id }));
              },
            },
          ]}
        />
        <TextOnMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit action',
              id: 'edit_prop',
              handleAction: (obj) => {
                EditSwitchControlModal({
                  widgetId: obj.id,
                  propId: buttonOnText().id,
                  propKey: 'targetControlOn',
                })
                  .then()
                  .catch(() => {});
              },
            },
            {
              icon: <HistoryIcon />,
              title: 'Show history',
              id: 'show_history',
              disabled: true,
              handleAction: (obj) => {
                setIdProperty(obj.propId);
                dispatch(setSettings({ isShowHistoryProperty: true }));
              },
            },
          ]}
        />
        <TextOffMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit action',
              id: 'edit_prop',
              handleAction: (obj) => {
                EditSwitchControlModal({
                  widgetId: obj.id,
                  propId: buttonText().id,
                  propKey: 'targetControlOff',
                })
                  .then()
                  .catch(() => {});
              },
            },
            {
              icon: <HistoryIcon />,
              title: 'Show history',
              id: 'show_history',
              disabled: true,
              handleAction: (obj) => {
                setIdProperty(obj.propId);
                dispatch(setSettings({ isShowHistoryProperty: true }));
              },
            },
          ]}
        />

        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>
        {objectId() && rpc ? (
          <StatusListItem valueStatus={valueStatus} rpc={rpc} objectId={objectId()} />
        ) : (
          <StatusListItemNull />
        )}
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Target</Typography>
        </ListSubheader>
        <ObjectListItem dataObject={dataObject} openColumnMenu={openColumnMenu} item={item} loading={loading} />
        <ControlListItem
          item={item}
          buttonText={valueActionRPC}
          openTextMenu={openTextMenu}
          openTextOnMenu={openTextOnMenu}
          buttonOnText={buttonOnText}
          openTextOffMenu={openTextOffMenu}
        />
        <ServiceSection classes={tabStyle} item={item} />
        <AccessSection classes={tabStyle} item={item} />
        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default CommandButtonGeneralTab;
